<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Profoma Invoices</h3>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Invoice No.</th>
                    <th>Profoma Date</th>
                    <th>Items</th>
                    <th>Amount</th>
                    <th>Tax</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="sales">
                <tr v-for="(sale,index) in sales" v-bind:key="sale.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ sale.invcNo }}</td>
                    <td>{{ sale.salesDt }}</td>
                    <td>{{ sale.totItemCnt }}</td>
                    <td>KES {{ sale.totAmt }}</td>
                    <td>KES {{ sale.totTaxAmt }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm" style="margin-right: 10px;">Edit</button>
                        <button class="btn btn-primary btn-sm">Delete</button>
                    </td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else>No Profoma found</p>
        </table>
        </div>
      </section>
  
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'

export default {
name: 'ProfomaPage',
components: {
    Header,
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        sales: []
    }
},
methods:{
    async getSales () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/profoma', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.sales = data.profoma
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Sale not found',
                text: data.message
            })
        }
    },
},
mounted(){
    this.getSales()
}
}
</script>