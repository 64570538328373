<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Import Management</h3>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Task Code</th>
                    <th>Declaration Date</th>
                    <th>Declaration No</th>
                    <th>Item Name</th>
                    <th>Supplier Name</th>
                    <th>Agent Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="imports.length > 0">
                <tr v-for="(importt,index) in imports" v-bind:key="importt.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ importt.taskCd }}</td>
                    <td>{{ importt.dclDe }}</td>
                    <td>{{ importt.dclNo }}</td>
                    <td>{{ importt.itemNm }}</td>
                    <td>{{ importt.spplrNm }}</td>
                    <td>{{ importt.agntNm }}</td>
                    <td>
                        <button v-if="isStringInArray(importt.taskCd)" class="btn btn-warning btn-sm">Already Updated</button>  
                        <button v-else class="btn btn-sm btn-primary" @click="openModal(importt)">Update</button>
                    </td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else style="color: red; padding: 20px; font-size: 21px;">No Import found</p>
        </table>
        </div>
      </section>
      <div v-show="isVisible" class="modal">
            <div class="modal-content">
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModal">&times;</span>
                <div>
                    <p>Task Code: {{ currentImport.taskCd }}</p>
                    <p>HS Code: {{ currentImport.hsCd }}</p>
                    <p>Item Name: {{ currentImport.itemNm }}</p>
                    <p>Quantity: {{ currentImport.qty }}</p>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3">
                        <label for="">Select Import Status</label>
                        <select v-model="status" class="form-control" style="width: 98%;" v-if="codes[10]">
                            <option v-for="(objItem) in codes[10].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="">Item to Map</label>
                        <select v-model="itemM" style="width: 98%;" v-if="items" class="form-control">
                            <option v-for="(objItem) in items" v-bind:key="objItem.id" :value="objItem">{{ objItem.itemName }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="">Remark</label>
                        <input type="text" class="form-control" v-model="remark">
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-sm" style="margin-top: 27px;" @click="updateImport">Update Import</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
import { toRaw } from 'vue'

export default {
name: 'ImportPage',
components: {
    Header,
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        imports: [],
        isVisible: false,
        codes: [],
        items: [],
        remark:"",
        itemM:{},
        status:"",
        currentImport:{},
        updatedImports: [],
    }
},
methods:{
    openModal(obj){
        this.isVisible = true;
        this.currentImport = toRaw(obj);
    },
    closeModal() {
        this.isVisible = false;
    },
    async getUpdateImport () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/imports/updated', requestOptions)
        const data = await res.json()
        console.log(data)
        this.updatedImports = data.imports
    },
    isStringInArray(task) {
        return this.updatedImports.some(obj => obj.taskCd.includes(task));
    },
    async updateImport() {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                "taskCd": this.currentImport.taskCd,
                "dclDe":this.currentImport.taskCd,
                "itemSeq":1,
                "hsCd":this.currentImport.taskCd,
                "itemClsCd": this.itemM.itemClassificationCode,
                "itemCd":this.itemM.itemCode,
                "itemId":this.itemM.id,
                "itemTyCd":this.itemM.itemType,
                "imptItemSttsCd": this.status,
                "imptItemSttsCdText":"approved",
                "remark":this.remark,
                "modrNm":"Admin",
                "modrId":"Admin",
                "details":this.currentImport
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/imports', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Import updated successfully',
                text: data.message
            })
            this.itemM = {}
            this.remark = ""
            this.status = ""
            this.currentImport = {}
            this.showModal = false
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Import not found',
                text: data.message
            })
        }
    },
    async getImports () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/imports', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.imports = data.imports
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Imports not found',
                text: data.message
            })
        }
    },
    async getCodes () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items/pullcodes', requestOptions)
        const data = await res.json()
        this.codes = data;
    },
    async getItems () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.items = data.items
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not found',
                text: data.message
            })
        }
    },
    toRawObject(proxyObject) {
        if (proxyObject === null || typeof proxyObject !== 'object') {
            return proxyObject;
        }

        if (Array.isArray(proxyObject)) {
            return proxyObject.map(item => this.toRawObject(item));
        }

        const rawObject = {};
        for (const key of Object.keys(proxyObject)) {
            rawObject[key] = this.toRawObject(proxyObject[key]);
        }
        return rawObject;
    }

},
mounted(){
    this.getImports()
    this.getCodes()
    this.getItems()
    this.getUpdateImport()
}
}
</script>


<style scoped>
.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>