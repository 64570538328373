<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Branch List</h3>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>PIN</th>
                    <th>Branch ID</th>
                    <th>Branch Name</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody v-if="branches?.length > 0">
                <tr v-for="(branch,index) in branches" v-bind:key="branch.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ branch.tin }}</td>
                    <td>{{ branch.bhfId }}</td>
                    <td>{{ branch.bhfNm }}</td>
                    <td>{{ branch.sctrNm }}, {{ branch.dstrtNm }}, {{ branch.prvncNm }}</td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else  style="color: red; padding: 20px; font-size: 21px;">No Branch found</p>
        </table>
        </div>
      </section>

      
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Notice Board</h3>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Notice No.</th>
                    <th>Title</th>
                    <th>URL</th>
                </tr>
            </thead>
            <tbody v-if="notices?.length > 0">
                <tr v-for="(notice,index) in notices" v-bind:key="notice.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ notice.noticeNo }}</td>
                    <td>{{ notice.title }}</td>
                    <td>{{ notice.dtlUrl }}</td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else  style="color: red; padding: 20px; font-size: 21px;">No eTims Notice found</p>
        </table>
        </div>
      </section>
  
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'

export default {
name: 'NoticePage',
components: {
    Header,
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        notices: [],
        branches: []
    }
},
methods:{
    async getBranches () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/businesses/branches', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.branches = data.branches
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Branch not found',
                text: data.message
            })
        }
    },
    async getNotices () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/notifications', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.notices = data.kranotifications
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Notice List not found',
                text: data.message
            })
        }
    },
},
mounted(){
    this.getNotices()
    this.getBranches()
}
}
</script>