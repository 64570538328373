<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Customer Management<button class="pull-right btn btn-primary" style="float:right;margin:10px;" @click="openModal">Add Customer</button></h3>

            <div :style="{ 'display': showAddModal }">
                <div style="width: 50%; padding:10px;">
                    <h5>Add new Customer</h5>
                    <label for="">Customer PIN</label>
                    <input type="text" name="pin" v-model="pin" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">Customer Name</label>
                    <input type="text" name="name" v-model="name" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">Customer Email</label>
                    <input type="email" name="email" v-model="email" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">Customer Phone</label>
                    <input type="text" name="phone" v-model="phone" class="form-control" style="background:#fff; border: 1px solid #000">
                    <button class="btn-style full" @click="postCustomer" style="margin-top: 10px;">Add Customer</button>
                </div>
            </div>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Customer Number</th>
                    <th>Customer PIN</th>
                    <th>Customer Name</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody v-if="customers.length > 0">
                <tr v-for="(customer,index) in customers" v-bind:key="customer.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ customer.custNo }}</td>
                    <td>{{ customer.custPin }}</td>
                    <td>{{ customer.custNm }}</td>
                    <td>{{ customer.telNo }}</td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else  style="color: red; padding: 20px; font-size: 21px;">No Customers found</p>
        </table>

        </div>
      </section>
  
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'

export default {
name: 'CustomerPage',
components: {
    Header
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        customers: [],
        showAddModal: "none",
        name: "",
        pin: "",
        phone: "",
        email: ""
    }
},
methods:{
    openModal() { this.showAddModal = "block"; },
    closeModal() { this.showAddModal = "none"; },
    async getCustomers () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/customers', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.customers = data.customers
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Customers not found',
                text: data.message
            })
        }
    },

    async postCustomer() {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
              pin: this.pin,
              name: this.name,
              email: this.email,
              mobile: this.phone
          })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/customers', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Customers created successful',
                text: data.message
            })
            this.getCustomers()
            this.showAddModal = "none"
            this.email = ""
            this.pin = ""
            this.phone = ""
            this.name = ""
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Customers not found',
                text: data.message
            })
        }

    }
},
mounted(){
    this.getCustomers()
}
}
</script>