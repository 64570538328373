import { createWebHistory, createRouter } from "vue-router";
import Login from "../views/auth/LoginPage.vue";
import Signup from "../views/auth/SignupPage.vue";
// import Validate from "../views/auth//ValidatePage.vue";
import Initialize from "../views/auth//InitializePage.vue";
import Training from "../views/training/TrainingPage.vue";
import ItemPage from "../views/items/ItemPage.vue";
import ImportPage from "../views/imports/ImportPage.vue";
import PurchasePage from "../views/purchases/PurchasePage.vue";
import CreditPage from "../views/sales/CreditPage.vue";
import ProfomaPage from "../views/sales/ProfomaPage.vue";
import SalesPage from "../views/sales/SalesPage.vue";
import MakeSalePage from "../views/sales/MakeSalePage.vue";
import MakeCreditNotePage from "../views/sales/MakeCreditNotePage.vue";
import CustomerPage from "../views/customers/CustomerPage.vue";
import InsurancePage from "../views/insurance/InsurancePage.vue";
import NoticePage from '../views/notices/NoticePage.vue';
import UserPage from '../views/user/UserPage.vue';
import Movement from '../views/movement/MovementPage.vue';

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
    // {
    //     path: "/validate",
    //     name: "Validate",
    //     component: Validate,
    // },
    {
        path: "/initialize",
        name: "Initialize",
        component: Initialize,
    },
    {
        path: "/items",
        name: "ItemPage",
        component: ItemPage,
    },
    {
        path: "/imports",
        name: "ImportPage",
        component: ImportPage,
    },
    {
        path: "/purchases",
        name: "PurchasePage",
        component: PurchasePage,
    },
    {
        path: "/sales",
        name: "SalesPage",
        component: SalesPage,
    },
    {
        path: "/sales/make",
        name: "MakeSalePage",
        component: MakeSalePage,
    },
    {
        path: "/sales/credit",
        name: "MakeCreditNotePage",
        component: MakeCreditNotePage,
    },
    {
        path: "/profoma",
        name: "ProfomaPage",
        component: ProfomaPage,
    },
    {
        path: "/credit",
        name: "CreditPage",
        component: CreditPage,
    },
    {
        path: "/customer",
        name: "CustomerPage",
        component: CustomerPage,
    },
    {
        path: "/insurance",
        name: "InsurancePage",
        component: InsurancePage,
    },
    {
        path: "/notice",
        name: "NoticePage",
        component: NoticePage,
    },
    {
        path: "/user",
        name: "UserPage",
        component: UserPage,
    },
    {
        path: "/training",
        name: "Training",
        component: Training,
    },
    {
        path: "/movement",
        name: "Movement",
        component: Movement,
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;