import { createApp } from 'vue'


import App from './App.vue'


import router from './router' // <---
import '@mdi/font/css/materialdesignicons.css'
import Antd from "ant-design-vue";
import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'

import './assets/scss/main.scss';

import "primeicons/primeicons.css";

import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import "primevue/resources/themes/md-light-indigo/theme.css"
import Tooltip from 'primevue/tooltip';

import ConfirmationService from 'primevue/confirmationservice';
import 'vuetify/styles';
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";




const vuetify = createVuetify({
    components,
    directives,
    ...labsComponents
})





const app = createApp(App);

app.use(PrimeVue)
app.use(Antd)
app.use(router)
app.use(ConfirmationService);
app.use(vuetify)
app.mount("#app")
app.directive('tooltip', Tooltip);

// createApp(App).use(Antd).use(PrimeVue).use(router).mount("#app")

