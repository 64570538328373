<template>
  <div class="login">

    <Header :loginHeader="true" />

    <section id="login">
      <div class="container text-center">
        <div class="login-wrapper">
          <h3>iSale Web </h3>
          <p>Login</p>
          <div class="form">
            <div class="form-group">
              <input type="text" placeholder="Business PIN" v-model="pin" class="form-control">
            </div>
            <div class="form-group">
              <input type="text" placeholder="Username" v-model="username" class="form-control">
            </div>
            <div class="form-group">
              <input type="password" placeholder="Password" v-model="password" class="form-control">
            </div>
            <div class="form-group">
              <input type="button" value="Login" @click="loginSubmit" class="btn-style full">
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'

export default {
  name: 'HomePage',
  components: {
    Header,
  },
  data: () => ({
    pin: "",
    username: "",
    password: "",
    deviceId: '',
    deviceDescription: '',
    rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 4) || 'Min 4 characters',
    ]
  }),
  methods:{
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + `domain=stanbestgroup.com;`;
    },
    async loginSubmit () {
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
              pin: this.pin,
              username: this.username,
              password: this.password,
              platform: "web",
              device_description: this.deviceDescription,
          })
      };
      const res = await fetch(process.env.VUE_APP_URL +'/auth/login', requestOptions)
      const data = await res.json()
      if(data.status == true){
        console.log({data})
          document.cookie = 
          localStorage.setItem('access_token', JSON.stringify(data.access_token))
          this.setCookie("access_token", data.access_token, 14)
          this.$router.push("/items")
          // if(data.isready == true){
          //   this.$router.push("/items")
          // } else{
          //   this.$router.push("/initialize")
          // }
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Login failed',
              text: data.message
          })
      }
    },
    getDeviceInformation() {
      // Check if the browser supports the necessary properties
      if ('navigator' in window && 'deviceMemory' in navigator && 'hardwareConcurrency' in navigator) {
        // Generate a unique ID based on available device properties
        this.deviceId = this.generateDeviceId();

        // Get additional device description
        this.deviceDescription = navigator.userAgent;
      } else {
        console.error('Device information not supported in this browser.');
      }
    },
    generateDeviceId() {
      // You can customize this method based on the properties you want to include in the ID
      const id = `${navigator.userAgent}-${navigator.platform}-${navigator.language}`;
      // Use a hashing library or other techniques for a more secure and unique ID
      return id;
    },
  },
  mounted() {
    this.getDeviceInformation();
  },
}
</script>