
<template>
    
    <div class="transaction">
        <Toaster />
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          
          <v-card
            color="grey-lighten-4"
            height="50px"
            style="width: 90vw; margin-bottom: 8px; margin-left: auto; margin-right: auto; display: block"
            rounded="0"
            flat
          >
            <v-toolbar density="compact">
            
            
            <v-toolbar-title style="font-family: Roboto; font-weight: bold; margin:0; padding:0 10px">Sales Invoices</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-tooltip location="top" text="Upload Sales CSV">
            <template v-slot:activator="{ props }">
                <v-btn
                role="button"
                icon
                v-bind="props"
                @click="openUpModal"
                >
                <v-icon>mdi-upload</v-icon> <!-- Upload icon -->
                </v-btn>
            </template>
            </v-tooltip>


            <!-- Upload Modal -->
            <!-- <v-dialog v-model="showUploadModal" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Upload CSV</span>
              </v-card-title>
              <v-card-subtitle>
                <v-file-input
                  v-model="file"
                  label="Select CSV file"
                  accept=".csv"
                  @change="handleFileUpload"
                ></v-file-input>
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeUpModal">Cancel</v-btn>
                <v-btn color="primary" @click="submitFile">Upload</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->

          <!-- Upload Modal -->
                <v-dialog v-model="showUploadModal" max-width="500px">
                <v-card>
                    <v-card-title>
                    <span class="headline">Upload CSV</span>
                    </v-card-title>
                    <v-card-text>
                    <!-- Download Template Button -->
                    <v-btn
                        block
                        color="secondary"
                        size="large"
                        class="mb-4"
                        @click="downloadTemplate"
                    >
                        Download Template
                    </v-btn>
                    
                    <v-file-input
                        v-model="file"
                        label="Select CSV file"
                        accept=".csv"
                        @change="handleFileUpload"
                        :placeholder="file ? file.name : 'No file selected'"
                        prepend-icon="mdi-file-upload" 
                    ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeUpModal">Cancel</v-btn>
                    <v-btn color="primary" @click="submitFile" :disabled="!file">Upload</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>


                
                <v-tooltip location="top" text="Make A Sale">
                    <template v-slot:activator="{ props }">
                    <v-btn role="link" href="/sales/make" icon v-bind="props">
                        <v-icon>mdi-cart</v-icon>
                    </v-btn>
                    </template>
                </v-tooltip>

                <v-tooltip location="top" text="Data Tools">
                    <template v-slot:activator="{ props: tooltip }">
                    <v-menu>
                        <template v-slot:activator="{ props: menu }">
                            <v-btn icon v-bind="mergeProps(menu, tooltip)">
                                <v-icon>mdi-database-search</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(item, index) in dataTools"
                            :key="index"
                            :value="index"
                            >
                                <v-list-item-title style="font-family: Roboto; font-size: 13px;">
                                    <!-- <v-dialog v-if="item.title == 'Import Sale Data'" max-width="900">
                                        <template v-slot:activator="{ props: activatorProps }">
                                            <span v-bind="activatorProps">
                                                <v-icon>{{ item.icon }}</v-icon>
                                                {{ item.title }}
                                            </span>
                                            
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            <v-card style="font-family: Roboto;" title="Download the template format">
                                            
                                            <template v-slot:text>
                                                <v-card-text>
                                                <span style="display: block; color: #3A3A3D;">
                                                    Click the button below to download the format template that you'll need to
                                                    upload your sales<br /><br />
                                                    
                                                    <b>NOTE:</b> The <b>date_of_invoicing</b> should be in the format <b>DD/MM/YYYY</b>
                                                 </span>
                                                </v-card-text>
                                                <v-btn
                                                    class="text-none mb-4"
                                                    color="indigo-darken-3"
                                                    size="x-large"
                                                    variant="flat"
                                                    block
                                                    role="link"
                                                    href="https://docs.google.com/spreadsheets/d/11ZgzFTxSBEv_dC1t9QOdb5L_xlJvClf5nJBkZwQu54w/export?format=csv"
                                                    target="_blank"
                                                >
                                                    Download Template
                                                </v-btn>
                                                <hr/>
                                                
                                                <h5>Upload Sales Template</h5>
                                                <v-file-input counter label="File input" accept="text/csv" show-size></v-file-input>
                                            </template>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>

                                                <v-btn
                                                text="Close Dialog"
                                                @click="isActive.value = false"
                                                ></v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </template>
                                    </v-dialog> -->
                                    <v-dialog v-if="item.title == 'Export to CSV'" max-width="900">
                                        <template v-slot:activator="{ props: activatorProps }">
                                            <span v-bind="activatorProps">
                                                <v-icon>{{ item.icon }}</v-icon>
                                                {{ item.title }}
                                            </span>
                                            
                                        </template>
                                        <template v-slot:default="{ isActive }">
                                            
                                            <v-card style="font-family: Roboto;" title="Set Configurations For your Report">
                                                
                                            <template v-slot:text>
                                                <v-card-text>
                                                <span style="display: block; color: #3A3A3D; font-size: 16px; box-sizing: content-box;">
                                                    Select the Date Range<br />
                                                    
                                                 </span>
                                                 <span style="color: #8a8a8d">
                                                    This is covers the start and end date for the report <br />
                                                 </span>
                                                </v-card-text>
                                                <div>
                                                    <span style="display: block; font-family: Roboto; font-size: 12px">Today's date is the default date</span>
                                                    <div>
                                                        <v-date-input
                                                        v-model="reportDate"
                                                        label="Select range"
                                                        max-width="368"
                                                        multiple="range"
                                                        clearable
                                                        required
                                                        ></v-date-input>
                                                    </div>
                                                    
                                                </div>
                                                <v-spacer></v-spacer>
                                                <hr/>
                                                
                                                <h5>Format The Column Names</h5>
                                                <span style="display: block; font-family: Roboto; font-size: 15px; color: #8A8A8D;">You can choose to customize the column names to suite you or leave the defaults</span>

                                                <div>
                                                    <v-text-field 
                                                        v-model="customer_name"
                                                        placeholder="custNm"
                                                        label="Customer Name Column (custNm)"
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="invcNo"
                                                        placeholder="invcNo"     
                                                        label="KRA Invoice Number Column (invcNo)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="trdInvcNo"
                                                        placeholder="trdInvcNo"
                                                        label="Trader Invoice Number Column (trdInvcNo)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="customer_pin"
                                                        placeholder="custTin"
                                                        label="Customer Pin Column (custTin)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="itemCount"
                                                        placeholder="totItemCnt"
                                                        label="Total Item Count Column (totItemCnt)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="totalTaxblAmt"
                                                        placeholder="totTaxblAmt"
                                                        label="Tax Taxable Amount Column (totTaxblAmt)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="totalTxAmt"
                                                        placeholder="totTaxAmt"
                                                        label="Total Tax Amount Column (totTaxAmt)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="totalAmt"
                                                        placeholder="totAmt"
                                                        label="Total Amount Column (totAmt)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="long_url"
                                                        placeholder="long_url"
                                                        label="ETIMS link Column (long_url)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="invcStatus"
                                                        placeholder="status"
                                                        label="Invoice Status Column (status)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="intrlData"
                                                        placeholder="intrlData"
                                                        label="KRA Internal Data Column (intrlData)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        v-model="rcptSign"
                                                        placeholder="rcptSign"
                                                        label="KRA receipt sign Column (rcptSign)"
                                                        clearable
                                                        style="font-family: Roboto;"
                                                    ></v-text-field>
                                                    
                                                </div>
                                            </template>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <!-- <a-button loading style="color: white" type="primary" @click="generateReport">Export To CSV</a-button> -->
                                                <v-btn
                                                    :loading="loader"
                                                    class="flex-grow-1"
                                                    height="48"
                                                    variant="tonal"
                                                    @click="generateReport"
                                                >
                                                    Export To CSV
                                                </v-btn>
                                                <v-btn
                                                text="Exit"
                                                @click="isActive.value = false"
                                                ></v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </template>
                                    </v-dialog>
                                    <!-- <span v-else>
                                        <v-icon>{{ item.icon }}</v-icon>
                                        {{ item.title }}
                                    </span> -->
                                    
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    </template>

                </v-tooltip>

            <v-tooltip location="top" text="Clear Filters">
                <template v-slot:activator="{ props }">
                    <v-btn @click="clearFilters" icon v-bind="props">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>
            </v-toolbar>
        </v-card>

        <a-table 
            :columns="columns" 
            :loading="loading" 
            class="ant-table-striped" 
            :dataSource="state.list" 
            :pagination="pagination"
            style="font-family: Roboto;" 
            @change="handleTableChange"
            bordered
            
            size="small"
        >
            <template #headerCell="{ column }">
                <template v-if="column.key === 'custNm'">
                    <span style="color: #1890ff">Name</span>
                </template>
            </template>
            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                    <a-input
                    ref="searchInput"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />
                        
                    <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >
                        <template #icon><SearchOutlined /></template>
                        Search
                    </a-button>
                    <a-button class="reset" size="small" style="width: 90px" @click="handleReset(clearFilters)">
                        Reset
                    </a-button>
                </div>
            </template>
            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <template #bodyCell="{ column, index, record, text }">
                <span v-if="state.searchText && state.searchedColumn === column.dataIndex">
                    <template>
                    
                    <template>{{ fragment }}</template>
                    </template>
                </span>
                <span v-if="column.dataIndex == 'status'">
                    <a-tag v-if="text == 'active'" color="green" style="display: flex; align-items: center; font-family: Roboto; font-size: 13px">
                        <template #icon>
                            <check-circle-outlined />
                        </template>
                        <span style="font-size: 13px;">Successful</span>
                    </a-tag> 
                    <a-tag v-else-if="text == 'pending'" color="blue" style="display: flex; align-items: center; font-family: Roboto; font-size: 13px">
                        <template #icon>
                            <clock-circle-outlined />
                        </template>
                        <span style="font-size: 13px;">Pending</span>
                    </a-tag>
                </span>
                <template v-if="column.dataIndex == 'action'">
                    <a-dropdown>
                        <template #overlay>
                            <a-menu @click="handleMenuClick">
                            <a-menu-item style="font-family: Roboto;" @click="openModal(record)" key="1">Credit Sale</a-menu-item>
                            <a-menu-item style="font-family: Roboto;" @click="openModalSale(record)" key="2">View Sale</a-menu-item>
                            <a-menu-item style="font-family: Roboto;" @click="generateSaleQR(record)" key="3">Generate QR</a-menu-item>
                            </a-menu>
                        </template>
                        <a-button>
                            <span style="font-family: Roboto;">Actions</span>
                            <DownOutlined />
                        </a-button>
                    </a-dropdown>
                    <!-- <button class="btn btn-primary btn-sm" style="margin-right: 10px;" @click="openModal(record)">Credit Sale</button>
                    <button class="btn btn-default btn-sm" style="margin-right: 10px; border: 1px solid #000;" @click="openModalSale(record)">View Sale</button>
                    <button class="btn btn-secondary btn-sm" style="margin-right: 10px" @click="generateSaleQR(record)">Generate QR</button> -->
                </template>
                <template v-else-if="column.dataIndex == 'no'">
                    <span style="font-size: 13px;">{{ index + 1 }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'custTin'">
                    <span style="font-size: 13px;">{{ text }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'invcNo'">
                    <span style="font-size: 13px;">{{ text }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'trdInvcNo'">
                    <span style="font-size: 13px;">{{ text }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'custNm'">
                    <span style="font-size: 13px;">{{ text }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'totAmt'">
                    <span style="font-size: 13px;">{{ formatToMoney(text) }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'totTaxAmt'">
                    <span style="font-size: 13px;">KES {{ formatToMoney(text) }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'createdAt'">
                    <span style="font-size: 13px;">{{ formatCreatedDate(text) }}</span>
                </template>
            </template>
        </a-table>


        </div>
      </section>

      
      <div v-show="isVisible" class="modal" style="font-family: Roboto">
            <div class="modal-content">
                <h3 style="font-family:Roboto">Make a credit note of this Sale Invoice Number {{ sale.invcNo }}</h3>
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModal">&times;</span>
                <div class="row">
                    <p>Update Customer Details</p>
                    <div class="col-md-3">
                        <label style="font-family: Roboto; font-size:15px" for="">Customer Pin</label>
                        <input style="font-family: Roboto; font-size:'10px !important'" type="text" v-model="customerPin" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label style="font-family: Roboto; font-size:15px" for="">Customer Name</label>
                        <input style="font-family: Roboto; font-size:'10px !important'" type="text" v-model="customerName" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label style="font-family: Roboto; font-size:15px" for="">Customer Email</label>
                        <input style="font-family: Roboto; font-size:'10px !important'" type="text" v-model="customerEmail" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label style="font-family: Roboto; font-size:15px" for="">Customer Mobile</label>
                        <input style="font-family: Roboto; font-size:'10px !important'" type="text" v-model="customerMobile" class="form-control">
                    </div>
                    <hr>
                    <p style="font-family: Roboto; font-size:15px">Credit Note Details</p>
                    <div class="col-md-5">
                        <label style="font-family: Roboto; font-size:15px" for="">Select Credit Note Reason</label>
                        <select v-model="status" class="form-control" style="width: 98%;" v-if="codes[11]">
                            <option v-for="(objItem) in codes[11].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                        </select>
                        <p v-else>Wait. Loading ...</p>
                    </div>
                    <div class="col-md-7">
                        <label for="">Remarks</label>
                        <input type="text" v-model="remarks" class="form-control" />
                    </div>
                
                </div>
                <table class="table table-bordered macho" style="margin-top: 20px;">
                    <thead><th>Item Name</th><th>Item Code</th><th>Qty</th><th>Price</th><th>Discount</th><th>Total</th><th>Action</th></thead>
                    <tbody>
                        <tr v-for="(saleItem) in sale.items" v-bind:key="saleItem.itemSeq">
                            <td>{{ saleItem.itemNm }}</td>
                            <td>{{ saleItem.itemCd }}</td>
                            <td><input type="number" min="0" class="form-control" v-model="saleItem.qty"></td>
                            <td><input type="number" min="0" class="form-control" v-model="saleItem.prc"></td>
                            <td>KES {{ formatToMoney(saleItem.dcAmt) }}</td>
                            <td>KES {{ formatToMoney(saleItem.totAmt) }}</td>
                            <td>
                                <button v-if="saleItem.updatedAt != 'done'" class="btn btn-primary btn-sm" @click="creditItem(saleItem)">Credit </button>
                                <button v-else class="btn btn-sm btn-warning">Done</button>
                            </td>
                        </tr>
                    </tbody>


                    <!-- <tbody>
                        <tr v-for="(saleItem) in sale.items" v-bind:key="saleItem.itemSeq">
                        <td>{{ saleItem.itemNm }}</td>
                        <td>{{ saleItem.itemCd }}</td>
                        <td><input type="number" min="0" class="form-control" :value="saleItem.qty" @input="updateSaleItem(saleItem, 'qty', $event.target.value)"></td>
                        <td><input type="number" min="0" class="form-control" :value="saleItem.prc" @input="updateSaleItem(saleItem, 'prc', $event.target.value)"></td>
                        <td>KES {{ formatToMoney(saleItem.dcAmt) }}</td>
                        <td>KES {{ formatToMoney(saleItem.totAmt) }}</td>
                        <td>
                            <button v-if="saleItem.updatedAt != 'done'" class="btn btn-primary btn-sm" @click="creditItem(saleItem)">Credit</button>
                            <button v-else class="btn btn-sm btn-warning">Done</button>
                        </td>
                        </tr>
                    </tbody> -->
                </table>

                <div style="background-color: #f2f2f2;">
                    <table style="font-family: Roboto, sans-serif" class="table table-hover">
                        <tr>
                            <td>Sub Total</td>
                            <td>{{ formatToMoney(totTaxblAmt-totTaxAmt) }}</td>
                        </tr>
                        <tr>
                            <td>Vat</td>
                            <td>{{ formatToMoney(totTaxAmt) }}</td>
                        </tr>
                        <tr>
                            <td>Total Amount</td>
                            <td>{{ formatToMoney(totTaxblAmt) }}</td>
                        </tr>
                    </table>
                </div>
                <button class="btn-style" style="float:right; margin: 20px;" @click="creditSale" v-if="isClickCredit">Make Credit Note</button>
            </div>
        </div>

        
      <div v-show="isVisibleSale" class="modal">
            <div class="modal-content">
                <h3>Sale Invoice Number {{ sale.invcNo }}</h3>
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModalSale">&times;</span>
                <div class="row">
                    <table class="table table-hover">
                        <tbody>
                            <tr><td>Date of Transaction</td><td>{{ formatDate(selectedSale.createdAt) }}</td></tr>
                            <tr><td>Sale Total Amount</td><td>KES {{ formatToMoney(selectedSale.totAmt) }}</td></tr>
                            <tr><td>Sale Total Tax</td><td>KES {{ formatToMoney(selectedSale.totTaxAmt) }}</td></tr>
                            <tr><td>Customer Details</td><td>{{ selectedSale.custTin }} - {{ selectedSale.custNm }} </td></tr>
                            <tr><td>eTims Link:</td><td><a target="_blank" :href="getEtimsLink()" class="btn btn-default btn-sm" style="border: 1px solid #000;;">Open </a></td></tr>
                            <tr><td>Sale Items</td><td>count: {{ selectedSale.totItemCnt }}</td></tr>
                            <tr><td>No.</td><td>Item Code</td><td>Item Name</td><td>Quantity</td><td>Price</td><td>Disc</td><td>Total</td></tr>
                            <tr v-for="(item,index) in selectedSale.items" v-bind:key="item.id">
                                <td>{{ (index+1) }}</td>
                                <td>{{ item.itemCd }}</td>
                                <td>{{ item.itemNm }}</td>
                                <td>{{ item.qty }}</td>
                                <td>{{ item.prc }}</td>
                                <td>{{ item.dcAmt }}</td>
                                <td>{{ item.totAmt }}</td>
                            </tr>
                        </tbody>
                    </table>

                    
                </div>
            </div>
        </div>
  
    </div>

</template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
import { Toaster } from "@steveyuowo/vue-hot-toast";
import "@steveyuowo/vue-hot-toast/vue-hot-toast.css";
import "primevue/resources/themes/md-light-indigo/theme.css"
import { toRaw, reactive, ref, computed, defineComponent, watch, mergeProps } from 'vue'

import { usePagination } from "vue-request";
import { SearchOutlined, CheckCircleOutlined, DownOutlined,ClockCircleOutlined } from "@ant-design/icons-vue";
import axios from 'axios';
import { VDateInput } from 'vuetify/lib/labs/components.mjs';

import dayjs from 'dayjs';



const dateFormat = 'YYYY/MM/DD';

const value4 = ref([dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]);


const dialog = ref(false)
const loader = ref(false)



const state = reactive({
  searchText: '',
  searchedColumn: '',
  list: [],
  total: 0,
  current: 1,
  pageSize: 0,
  totalPages: 0
});

var [tax_amount_b, taxable_amount_b] = [0, 0];


const searchInput = ref();


const queryData = async (params) => {
    
    let { page, results, custTin, custNm, invcNo, status, trdInvcNo } = params
    let offset = (page -  1) * results;
    console.log("********************************PARAMETERS**********************")
    console.log(params)
    let token = JSON.parse(localStorage.getItem('access_token'))
    // Example value with special character #
    const encodedInvcNo = encodeURIComponent(trdInvcNo);
    
    if(!custTin && !custNm && !invcNo && !status && !trdInvcNo) {
        let response = await axios.get(process.env.VUE_APP_URL + `/sales/?offset=${offset}&limit=${results}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })

        if (response) {
        window.scrollTo({
            top:0,
            behavior: "smooth"
        })
        // let resetBtns = document.getEelementsByClassName("reset")
        return response.data;
           
        }
    } else {
        
        let response = await axios.get(process.env.VUE_APP_URL + `/sales/?offset=${offset}&limit=${results}&custTin=${custTin}&custNm=${custNm}&invcNo=${invcNo}&status=${status}&trdInvcNo=${encodedInvcNo}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })

        if (response) {
        window.scrollTo({
                top:0,
                behavior: "smooth"
        })
        return response.data;
           
    }
    }
   
}


export default defineComponent ({

    name: 'SalePage',
    components: {
        SearchOutlined,
        Header,
        Toaster,
        CheckCircleOutlined,
        DownOutlined,
        ClockCircleOutlined,
        VDateInput
    },

    setup() {
        const { data, pageSize, run, current, loading } = usePagination(queryData, {
            formatResult: res => res.data,
            pagination: {
                currentKey: 'page',
                pageSizeKey: 'results',
                totalKey: state.total,
                totalPageKey: state.totalPages
            },
            
        });

        
        const pagination = computed(() => ({
            total: state.total,
            current: current.value,
            pageSize: pageSize.value,
            // totalPageKey: state.totalPages
        }))

       

        watch(data, (newValue) => {
            state.total = newValue.total
            state.list = newValue.sales
            state.pageSize = pageSize
            state.totalPages = newValue.totalPages
            
        });

    

        const handleTableChange = (pag, filters, sorter) =>{
            console.log("*********************MY ROBBINS***********************")
            console.log(filters)
            console.log(pag)
            console.log(sorter)
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            });
        };

        

        return {
            pageSize,
            loading,
            pagination,
            run,
            handleTableChange,
            state,
            dialog,
            value4,
            loader,
        }
    },

    data(){
        return{
            dates: [],
            dateRange: {
                startDate: new Date(),
                endDate: new Date()
            },
            options: {
                locale: {
                    format: "DD/MM/YYYY",
                    separator: " - "
                },
                alwaysShowCalendars: true
            },
            reportDate: null,
            fromDate: null,
            toDate: null,
            customer_name: null,
            invcNo: null,
            trdInvcNo: null,
            customer_pin: null,
            itemCount: null,
            txbleAmtA: null,
            txbleAmtB: null,
            txbleAmtC: null,
            txbleAmtD: null,
            txbleAmtE: null,
            txAmtA: null,
            txAmtB: null,
            txAmtC: null,
            txAmtD: null,
            txAmtE: null,
            totalTaxblAmt: null,
            totalTxAmt: null,
            totalAmt: null,
            long_url: null,
            invcStatus: null,
            intrlData: null,
            rcptSign: null,
            showUploadModal: false,
            file: null,
            dataTools: [
                { title: 'Export to CSV', icon: "mdi-chart-box-outline" },
            ],
            columns: computed(()=>{
              
                return [
                {
                    title: "Name",
                    dataIndex: "custNm",
                    key: "custNm",
                    customFilterDropdown: true,
                    ellipsis: true,
                    onFilter: async (value, record) => {
                        
                        record.custNm?.toString().toLowerCase().includes(value.toLowerCase()) 
                    },
                    onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                        searchInput?.value?.focus();
                        }, 100);
                     }
                    }
                },
                {
                    title: "Pin",
                    dataIndex: "custTin",
                    key:"custTin",
                    customFilterDropdown: true,
                    onFilter: (value, record) => record.custTin?.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                        searchInput?.value?.focus();
                        }, 100);
                     }
                    }
                },
                {
                    title: 'Invoice No',
                    dataIndex: 'invcNo',
                    key: 'invcNo',
                    customFilterDropdown: true,
                    onFilter: (value, record) => record.invcNo?.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                        searchInput?.value?.focus();
                        }, 100);
                     }
                    }
                },
                {
                    title: 'Trader Invoice No',
                    dataIndex: 'trdInvcNo',
                    key: 'trdInvcNo',
                    customFilterDropdown: true,
                    onFilter: (value, record) => record.trdInvcNo?.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                        searchInput?.value?.focus();
                        }, 100);
                     }
                    }
                },
                {
                    title: "status",
                    dataIndex: "status",
                    key: "status",
                    filters: [
                        { text: 'Successful', value: 'active' },
                        { text: 'Pending', value: 'pending' },
                    ],
                },
                {
                    title: 'Amount',
                    dataIndex: 'totAmt',
                    key: 'totAmt',
                    ellipsis: true
                },
                {
                    title: 'Created At',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    ellipsis: true
                },
                {
                    title: "Action",
                    dataIndex: "action",
                    // fixed: "right",
                    width: 120
                }

            ]
            }),
            
            count: 0,
            isLoading: true,
            token: JSON.parse(localStorage.getItem('access_token')),
            sales: [],
            isVisible:false,
            isClickCredit: true,
            isVisibleSale:false,
            codes:[],
            customerPin:"",
            selectedSale:{},
            customerName:"",
            customerEmail:"",
            customerMobile:"",
            remarks:"",
            status:"",
            reason:"",
            sale: {},
            taxblAmtA: 0.00,
            taxblAmtB: 0.00,
            taxblAmtC: 0.00,
            taxblAmtD: 0.00,
            taxblAmtE: 0.00,
            taxRtA: 0,
            taxRtB: 16,
            taxRtC: 0,
            taxRtD: 0,
            taxRtE: 8,
            taxAmtA: 0,
            taxAmtB: 0,
            taxAmtC: 0,
            taxAmtD: 0,
            taxAmtE: 0,
            totTaxblAmt: 0.00,
            totTaxAmt: 0.00,
            totAmt: 0.00,
            creditItems: [],
        }
    },
    methods:{
        downloadTemplate() {
            const templateUrl = 'https://docs.google.com/spreadsheets/d/11ZgzFTxSBEv_dC1t9QOdb5L_xlJvClf5nJBkZwQu54w/export?format=csv';
            const link = document.createElement('a');
            link.href = templateUrl;
            link.download = 'sales_template.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        openUpModal() {
            this.showUploadModal = true;
        },
        closeUpModal() {
            this.showUploadModal = false;
        }, 
        handleFileUpload(event) {
        this.file = event.target.files[0];
        },

        submitFile() {
            if (this.file) {
            // Close the upload modal
            this.showUploadModal = false;

            // Show confirmation dialog
            Swal.fire({
                title: 'Confirm Upload',
                text: 'Are you sure you want to upload These Sales', 
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, upload',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                // User confirmed, proceed with upload
                this.uploadFile();
                } else {
                // User cancelled, do nothing
                console.log('Upload cancelled');
                // Optionally, you can reopen the upload modal if the user cancels
                // this.showUploadModal = true;
                }
            });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No File Selected',
                    text: 'Please select a file before uploading.'
                });
            }
        },

        async uploadFile() {
            this.loader = true; // Show loading indicator
            const formData = new FormData();
            formData.append('file', this.file);

            try {
            const response = await axios.post(process.env.VUE_APP_URL + '/sales/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`
                }
            });

            if (response.status === 200) {
                // Success
                Swal.fire({
                icon: 'success',
                title: 'Upload Successful',
                text: 'Your file has been uploaded and processed successfully.'
                });
            } else {
                // Error
                throw new Error('Upload failed');
            }
            } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'There was an error uploading your file. Please try again.'
            });
            } finally {
            this.loader = false; // Hide loading indicator
            this.file = null; // Reset the file input
            }
        },
    
        clearcustNm() {
            this.customer_name = ""
        },
        formatRangeDate(dateString, description) {
            let date;
            if (!dateString && description == 'fromDate') {
                date = new Date()
                date.setHours(0, 0, 0, 0)
                const localeDateString = date.toLocaleString();
                return localeDateString;
            } else if (!dateString && description == "toDate") {
                date = new Date()
                
                const localeDateString = date.toLocaleString();
                return localeDateString;
            }
            date = new Date(dateString)
            const localeDateString = date.toLocaleString();
            return localeDateString;
        },
        uploadItems(){
        this.displayUpload = "block"
        },
        closeUploadItems(){
            this.displayUpload = "none"
        },
        async generateReport() {

            this.loader = true;
            this.reportDate = this.reportDate || []
            let fromDate = this.formatRangeDate(this.reportDate[0], 'fromDate')
            let toDate = this.formatRangeDate(this.reportDate[this.reportDate.length - 1], "toDate")
            
            console.log(fromDate)
            console.log(toDate)
            try {
                let response = await axios.post(process.env.VUE_APP_URL + "/sales/excel", {
                    trdInvcNo:this.trdInvcNo,
                    invcNo:this.invcNo,
                    custTin: this.customer_pin,
                    custNm: this.customer_name,
                    totItemCnt: this.itemCount,
                    totTaxblAmt: this.totalTaxblAmt,
                    totTaxAmt: this.totalTxAmt,
                    status: this.invcStatus,
                    totAmt: this.totalAmt,
                    intrlData: this.intrlData,
                    rcptSign: this.rcptSign,
                    long_url: this.long_url,
                    fromDate,
                    toDate
                }, {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token'))}`,    
                    }
                })

                // Create a URL for the CSV blob
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));

                // Create a link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data.csv'); // Set the desired file name

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Clean up and remove the link
                document.body.removeChild(link);
                this.loader = false;
                this.clearDateRangeInput()
            } catch(error) {
                console.log(error)
                return error;
            }
        },
        clearDateRangeInput() {
            this.trdInvcNo = "", this.invcNo = ""
            this.customer_pin = "", this.customer_name = ""
            this.reportDate = new Date(), this.itemCount = ""
            this.totalTaxblAmt = "", this.totalAmt = ""
            this.totalTxAmt = "",
            this.invcStatus = "", this.totalAmt = ""
            this.intrlData = "", this.rcptSign = ""
            
            this.long_url = ""
        },
        mergeProps,
        formatCreatedDate(dateString) {
            const date = new Date(dateString);
            const options = { 
                day: '2-digit', 
                month: '2-digit', 
                year: 'numeric'
            };
            const formattedDate = date.toLocaleDateString('en-GB', options);
            return formattedDate;
        },
        getEtimsLink() {
            const { selectedSale } = this;
      
            // Check if rcptSign is available
            if (selectedSale?.rcptSign) {
                return `https://etims-sbx.kra.go.ke/common/link/etims/receipt/indexEtimsReceiptData?Data=${selectedSale.Business.pin}${selectedSale.Business.bhfId}${selectedSale.rcptSign}`;
            } else {
                return selectedSale.long_url; // Replace with the link you want to use when rcptSign is not available
            }
        },
        clearFilters() {
            location.reload()
        },
        async handleSearch(selectedKeys, confirm, dataIndex) {
            console.log("MY SELECTED KEYS")
            console.log(selectedKeys)
            
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;    
        },

        handleReset(clearFilters) {
            
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
            
        },
        openModal(obj){
            console.log(obj)
            this.isVisible = true;
            this.sale = toRaw(obj);
            this.customerPin = this.sale.receipt?.custTin;
            this.customerName = this.sale.receipt?.trdeNm;
            this.customerEmail = this.sale.receipt?.custEmail;
            this.customerMobile = this.sale.receipt?.custMblNo;
        },
        async generateSaleQR(data) {
            try {
                let response = await fetch(process.env.VUE_APP_URL + "/sales/v1/qr",{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.token}`,
                        "responseType": "blob"
                    },
                    body: JSON.stringify({
                        type: "sale",
                        id: parseInt(data.id)
                    })
                })

                
                
                if (response.ok) {
                    const blob = await response.blob();
                    const filename = `invoice-${data.invcNo}.pdf`;

                    // Create a temporary link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    // Append the link to the body
                    document.body.appendChild(link);

                    // Trigger the download
                    link.click();

                    // Clean up
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                }                
                
        } catch(error) {
            Swal.fire({
                title: "QR Code",
                text: error.message
            })
        } 

        },
        openModalSale(obj){
            this.isVisibleSale = true;
            this.selectedSale = obj
            console.log("Selected Sale Items:", this.selectedSale.items);
        },
        formatDate (rawDate) {
            if(rawDate){
                const dateNo = new Date(rawDate)
                var minutes = 0;
                if(dateNo.getMinutes() < 10){
                minutes = "0"+dateNo.getMinutes()
                } else {
                minutes = dateNo.getMinutes()
                }
                var hours = 0;
                if(dateNo.getHours() < 10){
                hours = "0"+dateNo.getHours()
                } else {
                hours = dateNo.getHours()
                }
                return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear()+" - "+hours+":"+minutes
            } else {
                return ""
            }
        },
        formatToMoney(value) {
            const formattedValue = Number(value).toFixed(2);
            return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        closeModal() {
            this.isVisible = false;
            this.totTaxblAmt = 0;
            this.totTaxAmt = 0;
        },
        closeModalSale(){
            this.isVisibleSale = false;
        },

        // updateSaleItem(saleItem, field, value) {
        // saleItem[field] = parseFloat(value);
        // this.recalculateTotals(saleItem);
        // },
        // recalculateTotals(saleItem) {
        // saleItem.totAmt = saleItem.qty * saleItem.prc - saleItem.dcAmt;
        // },
        creditItem(saleItemObj){
            let objItem = toRaw(saleItemObj);
            console.log("LAUNCHING ITEM")
            console.log(objItem)
            this.creditItems = this.creditItems.filter(obj => obj.itemSeq !== objItem.itemSeq);
            var taxitemAmt = 0
            var taxblAmt = 0
            var itemSubTotal = this.roundToTwo(objItem.qty * objItem.prc)
            if(objItem.taxTyCd == "B"){
                taxblAmt = this.roundToTwo(itemSubTotal)
                console.log("The tax Rate")
                console.log(taxblAmt)
                taxitemAmt = this.roundToTwo(itemSubTotal * 0.16)
                console.log("Tax item Amount")
                console.log(taxitemAmt)
                this.taxblAmtB = taxblAmt
                this.taxAmtB = taxitemAmt
                tax_amount_b += this.roundToTwo(this.taxAmtB);
                // changed next line to subtract tax instead of adding
                taxable_amount_b += this.roundToTwo(this.taxblAmtB - this.taxAmtB);
                // This is the official way it was
                // taxable_amount_b += this.roundToTwo(this.taxblAmtB);
                this.totAmt = this.roundToTwo(this.taxblAmtB + this.taxAmtB)

            } else if(objItem.taxTyCd == "E"){
                taxblAmt = this.roundToTwo(itemSubTotal)
                taxitemAmt = this.roundToTwo(itemSubTotal * 0.8)
                this.taxblAmtE = taxblAmt
                this.taxAmtE = this.roundToTwo(taxitemAmt)
                this.totAmt = this.roundToTwo(this.taxblAmtE + this.taxAmtE)
            } else {
                taxblAmt = itemSubTotal
                taxitemAmt = 0
                if(objItem.taxTyCd == "A"){
                    this.taxblAmtA = this.roundToTwo(this.taxblAmtA + itemSubTotal)
                    this.totAmt = this.roundToTwo(this.totAmt + itemSubTotal)
                } else if(objItem.taxTyCd == "C") {
                    this.taxblAmtC = this.roundToTwo(this.taxblAmtC + itemSubTotal)
                    this.totAmt = this.roundToTwo(this.totAmt + itemSubTotal)
                } else if(objItem.taxTyCd == "D") {
                    this.taxblAmtD = this.roundToTwo(this.taxblAmtD + itemSubTotal)
                    this.totAmt = this.roundToTwo(this.totAmt + itemSubTotal)
                }
                
            }

            this.totTaxblAmt = this.roundToTwo(this.totTaxblAmt + taxblAmt)
            this.totTaxAmt = this.roundToTwo(this.totTaxAmt + taxitemAmt)
        
            

            saleItemObj.updatedAt = "done";
            const obj={
                ... objItem,
                "dcRt": parseFloat(objItem.dcRt),
                "dcAmt": parseFloat(objItem.dcAmt),
                "pkg": parseFloat(objItem.qty),
                "qty": parseFloat(objItem.qty),
                "prc": parseFloat(objItem.prc),
                "splyAmt": parseFloat(itemSubTotal),
                "taxblAmt": parseFloat(taxblAmt),
                "taxAmt": parseFloat(taxitemAmt),
                "totAmt": parseFloat(this.totAmt)
            }
            this.creditItems.push(obj)
        },

    
        async creditSale () {
            this.isClickCredit = false
            const dateTimeNow = this.getCurrentDateTimeFormatted()
            const dateOnlyNow= this.getCurrentDateFormatted()

                let total_taxable_amt = this.creditItems.map(item => this.roundToTwo(item.taxblAmt)).reduce((a, b) => this.roundToTwo(a + b), 0)
                let total_tax_amt = this.creditItems.map(item => this.roundToTwo(item.taxAmt)).reduce((a, b) => this.roundToTwo(a + b), 0)
                
                let dataSet = { 
                    "trdInvcNo": "1",
                    "invcNo": "1",
                    "orgInvcNo": this.sale.invcNo,
                    "custType":"NEW",
                    "custTin": this.customerPin,
                    "custId": this.sale.receipt?.customerId,
                    "custNm": this.customerName,
                    "custEmail": this.customerEmail,
                    "custTel": this.customerMobile,
                    "salesTyCd": "N",
                    "rcptTyCd": "R",
                    "pmtTyCd": this.sale.pmtTyCd,
                    "salesSttsCd": "02",
                    "cfmDt": dateTimeNow,
                    "salesDt": dateOnlyNow,
                    "stockRlsDt": dateTimeNow,
                    "totItemCnt": this.creditItems.length,
                    "taxblAmtA": this.taxblAmtA,
                    "taxblAmtB": taxable_amount_b,
                    "taxblAmtC": this.taxblAmtC,
                    "taxblAmtD": this.taxblAmtD,
                    "taxblAmtE": this.taxblAmtE,
                    "taxRtA": 0,
                    "taxRtB": 16,
                    "taxRtC": 0,
                    "taxRtD": 0,
                    "taxRtE": 8,
                    "taxAmtA": this.taxAmtA,
                    "taxAmtB": tax_amount_b,
                    "taxAmtC": this.taxAmtC,
                    "taxAmtD": this.taxAmtD,
                    "taxAmtE": this.taxAmtE,
                    "totTaxblAmt":  total_taxable_amt,
                    "totTaxAmt": total_tax_amt,
                    // official one
                    // "totAmt": this.roundToTwo(total_taxable_amt + total_tax_amt),
                    // my fix
                    "totAmt": this.roundToTwo(total_taxable_amt),
                    "prchrAcptcYn": "N",
                    "remark": this.remark,
                    "regrId": "Admin",
                    "regrNm": "Admin",
                    "modrId": "Admin",
                    "modrNm": "Admin",
                    "receipt": this.sale.receipt ?? {
                        "id": null,
                        "custTin": "",
                        "custMblNo": "",
                        "custEmail": null,
                        "rptNo": 1295,
                        "rcptPbctDt": "20240311",
                        "trdeNm": this.customerName,
                        "adrs": "",
                        "topMsg": "iSale POS",
                        "btmMsg": "Stanbestgroup Ltd",
                        "prchrAcptcYn": "N",
                        "status": "active",
                        "createdAt": "2024-03-11T10:12:54.687Z",
                        "updatedAt": "2024-03-11T10:12:54.687Z",
                        "deletedAt": null,
                        "customerId": null
                    },
                    "itemList": this.creditItems
                }

                console.log("THIS IS MY DATASET")
                console.log(dataSet)
                
                
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                    body: JSON.stringify(dataSet)
                };
                
                const res = await fetch(process.env.VUE_APP_URL+'/credit', requestOptions)
                const data = await res.json()
                if(data.status == true){
                    Swal.fire({
                        icon: 'success',
                        title: 'Credit made successfully',
                        text: data.message
                    })
                    console.log(data)
                    this.isVisible = false
                    this.isClickCredit = true
                    location.reload()
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Credit not found',
                        text: data.message
                    })
                    this.isClickCredit = true
                }
        },
        convertStringToNumber(stringValue) {
            return parseFloat(stringValue);
        },
        roundToTwo(num) {
            var number = parseFloat(num);
            if (isNaN(number)) {
                return num; 
            }
            return this.convertStringToNumber(number.toFixed(2));
        },

        
        async getSales () {
            const requestOptions = {
                // method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            
            
            let res = await axios.get(process.env.VUE_APP_URL + '/sales/?limit=100&offset=0', requestOptions).then(resp=>resp.data);
            
            if(res.status == true){
                console.log("THIS IS DATA")
                console.log(res)
                this.count = res.count
                this.isLoading = false;
                this.sales = res.sales
            }else{
                this.isLoading = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Sale not found',
                    text: res.message
                })
            }
        },
        async getCodes () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/items/pullcodes', requestOptions)
            const data = await res.json()
            this.codes = data.kraResult.clsList;
        },
        getCurrentDateFormatted() {
            const now = new Date();
            const year = now.getFullYear();
            // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');

            return `${year}${month}${day}`;
        },
        getCurrentDateTimeFormatted() {
            const now = new Date();
            const year = now.getFullYear();
            // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');

            return `${year}${month}${day}${hours}${minutes}${seconds}`;
        }
    },
    mounted(){
        // this.dataAccess()
        this.getCodes()
      
    },
    watch: {
        'sale.items': {
            handler(newItems) {
                newItems.forEach((item, index) => {
                    let priceChange = item.prc * item.qty;
                    this.sale.items[index].totAmt = priceChange;
                });
            },
            deep: true
        }
    }

})
</script>


<style scoped>

.ant-table-wrapper {
    /* display: flex;
    justify-content: center;
    align-items: center;  */
    margin:0 auto;
    width: 90vw;
}
.ant-table, .ant-table-content {
    width: 90vw;
    margin:0 auto;    
}



.modal {
  display: block; 
  position: fixed; 
  z-index: 99; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.card {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;

}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal {
    font-family: Roboto, sans-serif;
}

.table:not(.macho) {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.sales-header {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto, sans-serif;
}

.p-datatable {
  border-collapse: collapse;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #dee2e6;
}
.v-field-label {
    font-family: Roboto, sans-serif !important;
}
</style>