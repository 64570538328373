<template>
    <div class="home">
      <Header :mainHeader="true"/>
  
      <div>
        
      </div>
    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  
  
  export default {
    name: 'TrainingPage',
    components: {
      Header,
    },
    data(){
      return{
  
      }
    }
  }
  </script>