
import { right } from '@popperjs/core';
<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Stock Movement
            <button class="btn btn-primary btn-sm" style="float:right;" @click="openModal">Move New Stock</button>
            <button class="btn btn-primary btn-sm" style="float:right;display: none;" @click="lookStock">Look Stock</button>
          </h3>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Destination Branch</th>
                    <th>Destination Business Name</th>
                    <th>Number of Items</th>
                    <th>Amount</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="movements">
                <tr v-for="(movement,index) in movements" v-bind:key="movement.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ movement.destinationBusiness.bhfId }}</td>
                    <td>{{ movement.destinationBusiness.bhfNm }}</td>
                    <td>{{ movement.totItemCnt }}</td>
                    <td>KES {{ movement.totAmt }}</td>
                    <td><button class="btn btn-primary btn-sm" style="margin-right: 10px;" @click="receiveStock(movement)">Receive Stock</button></td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else>No Stock movement found</p>
        </table>
        </div>
        <div v-show="isVisible" class="modal">
            <div class="modal-content">
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModal">&times;</span>
                <div class="row">
                    <div class="col-md-5">
                        <label for="">Select Branch to send Stock to</label>
                        <select v-model="branch" class="form-control" style="width: 98%;" v-if="branches">
                            <option v-for="(objItem) in branches" v-bind:key="objItem.id" :value="objItem.id">{{ objItem.pin }} - {{ objItem.bhfId }} - {{ objItem.bhfNm }}</option>
                        </select>
                    </div>
                    <div class="col-md-7">
                        <label for="">Remarks</label>
                        <input type="text" v-model="remarks" class="form-control" />
                    </div>                
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <label for="">Select item to Move</label>
                        <select v-model="item" style="width: 98%;" v-if="items" class="form-control">
                            <option v-for="(objItem) in items" v-bind:key="objItem.id" :value="objItem">{{ objItem.itemCd }} - {{ objItem.itemNm }}</option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label for="">Quantity</label>
                        <input type="number" min="1" v-model="quantity" class="form-control" />
                    </div>  
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-sm" style="margin-top: 29px;" @click="addItem">Add Item</button>
                    </div>                
                </div>
                <table class="table table-hover">
                    <thead><th>Item Code</th><th>Item Name</th><th>Quantity</th></thead>
                    <tbody>
                        <tr v-for="(mItem) in itemList" v-bind:key="mItem.itemSeq">
                            <td>{{ mItem.itemCd }}</td>
                            <td>{{ mItem.itemNm }}</td>
                            <td>{{ mItem.qty }}</td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn-style" style="float:right; margin: 20px;" @click="moveStock">Move Stock</button>
            </div>
        </div>
      </section>
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
import { toRaw } from 'vue'

export default {
name: 'MovementPage',
components: {
    Header,
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        movements: [],
        branches:[],
        branch:"",
        items:[],
        isVisible:false,
        item:{},
        currentStock:{},
        itemList:[],
        totTaxblAmt: 0.00,
        totTaxAmt: 0.00,
        totAmt: 0.00,
        quantity:"",
        remark:""
    }
},
methods:{
    openModal(){
        this.isVisible = true;
    },
    closeModal() {
        this.isVisible = false;
    },
    addItem(){
        if(this.quantity !== "0" && this.item.currentStock >= this.quantity){
            const itemSeq = this.itemList.length + 1
            const itemSubTotal = this.item.dftPrc * this.quantity
            var taxitemAmt = 0
            var taxblAmt = 0
            if(this.item.taxTyCd == "B"){
                taxblAmt = this.roundToTwo(itemSubTotal/1.16)
                taxitemAmt = this.roundToTwo(itemSubTotal - taxblAmt)
            } else if(this.item.taxTyCd == "E"){
                taxblAmt = this.roundToTwo(itemSubTotal/1.08)
                taxitemAmt = this.roundToTwo(itemSubTotal - taxblAmt)
            } else {
                taxblAmt = itemSubTotal
                taxitemAmt = 0
            }

            this.totTaxblAmt = this.roundToTwo(this.totTaxblAmt + taxblAmt)
            this.totTaxAmt = this.roundToTwo(this.totTaxAmt + taxitemAmt)
            this.totAmt = this.roundToTwo(this.totAmt + itemSubTotal)
            const obj={
                "itemSeq": itemSeq,
                "itemId":this.item.id,
                "itemCd": this.item.itemCd,
                "itemClsCd": this.item.itemClsCd,
                "itemNm": this.item.itemNm,
                "itemNmDef": this.item.itemNmDef,
                "bcd": this.item.bcd,
                "pkgUnitCd": this.item.pkgUnitCd,
                "pkg": this.quantity,
                "qtyUnitCd": this.item.qtyUnitCd,
                "qty": this.quantity,
                "prc": this.item.dftPrc,
                "splyAmt": this.roundToTwo(this.item.dftPrc * this.quantity),
                "totDcAmt": 0,
                "taxTyCd": this.item.taxTyCd,
                "taxblAmt": taxblAmt,
                "taxAmt": taxitemAmt,
                "totAmt": itemSubTotal
            }
            this.itemList.push(obj)
            this.item = {}
            this.quantity = 0
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Quantity',
                text: "Add a valid quantity amount"
            })
        }
    },
    async moveStock () {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({
                "sarNo": 1,
                "orgSarNo": 1,
                "destinationBusiness": this.branch,
                "custTin": "M",
                "custNm": "M",
                "custBhfId": "M",
                "regTyCd": "M",
                "sarTyCd": "11",
                "ocrnDt": this.getCurrentDateFormatted(),
                "totItemCnt": this.itemList.length,
                "totTaxblAmt": this.totTaxblAmt,
                "totTaxAmt": this.totTaxAmt,
                "totAmt": this.totAmt,
                "remark": this.remark,
                "regrNm": "Admin",
                "regrId": "Admin",
                "modrNm": "Admin",
                "modrId": "Admin",
                "itemList": this.itemList
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/stockmovement', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Stock moved successfully',
                text: data.message
            })
            console.log(data)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Stock not found',
                text: data.message
            })
        }
    },
    async receiveStock (obj) {
        const pObj = toRaw(obj);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                "stockId": pObj.id,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/stockmovement/receive', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Stock received successfully',
                text: data.message
            })
            console.log(data)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Stock not found',
                text: data.message
            })
        }
    },
    async lookStock () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/stockmovement/look', requestOptions)
        const data = await res.json()
        if(data.status == true){
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Stock Move List successfully',
            //     text: data.message
            // })
            console.log(data)
        }else{
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Stock not found',
            //     text: data.message
            // })
            console.log("Error getting move list")
        }
    },
    async getMovements () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/stockmovement', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.movements = data.movement
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Stock movements not found',
                text: data.message
            })
        }
    },
    async getItems () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.items = data.items
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not found',
                text: data.message
            })
        }
    },
    async getBranches () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/businesses/movement', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.branches = data.businesses
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Branch not found',
                text: data.message
            })
        }
    },
    roundToTwo(num) {
        return parseFloat(num.toFixed(2));
    },
    getCurrentDateFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');

      return `${year}${month}${day}`;
    },
    getCurrentDateTimeFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }
},
mounted(){
    this.getMovements()
    this.getItems()
    this.getBranches()
    this.lookStock()
}
}
</script>


<style scoped>
.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>