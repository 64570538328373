<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Purchase Management</h3>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Supplier Date</th>
                    <th>Supplier PIN</th>
                    <th>Supplier Name</th>
                    <th>Items</th>
                    <th>Amount</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="purchases?.length > 0">
                <tr v-for="(purchase,index) in purchases" v-bind:key="purchase.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ purchase.salesDt }}</td>
                    <td>{{ purchase.spplrTin }}</td>
                    <td>{{ purchase.spplrNm }}</td>
                    <td>{{ purchase.totItemCnt }}</td>
                    <td>KES {{ formatToMoney(purchase.totAmt) }}</td>
                    <td>
                        <button v-if="isStringInArray(purchase.spplrInvcNo)" class="btn btn-warning btn-sm">Already Updated</button>  
                        <button v-else class="btn btn-primary btn-sm" style="margin-right: 10px;" @click="openModal(purchase)">Update Purchase</button>
                    </td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else  style="color: red; padding: 20px; font-size: 21px;">No Purchases found</p>
        </table>
        </div>
        <div v-show="isVisible" class="modal">
            <div class="modal-content">
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModal">&times;</span>
                <div class="row">
                    <div class="col-md-5">
                        <label for="">Select Purchase Progress Status</label>
                        <select v-model="status" class="form-control" style="width: 98%;" v-if="codes[13]">
                            <option v-for="(objItem) in codes[13].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                        </select>
                    </div>
                    <div class="col-md-7">
                        <label for="">Remarks</label>
                        <input type="text" v-model="remarks" class="form-control" />
                    </div>
                
                </div>
                <table class="table table-hover">
                    <thead><th>Purchase Item</th><th>Store Item</th></thead>
                    <tbody>
                        <tr v-for="(purchaseItem) in currentPurchase.itemList" v-bind:key="purchaseItem.itemSeq">
                            <td>
                                <p>Item Code: {{ purchaseItem.itemCd }}</p>
                                <p>Item Classification Code: {{ purchaseItem.itemClsCd }}</p>
                                <p>Item Name: {{ purchaseItem.itemNm }}</p>
                                <p>Price: {{ purchaseItem.prc }}</p>
                                <p>Qty: {{ purchaseItem.qty }}</p>
                                <p>Tax: {{ purchaseItem.taxAmt }}</p>
                                <p>Total: {{ purchaseItem.totAmt }}</p>
                            </td>
                            <td>
                                <label for="">Select Item</label>
                                <select v-model="item" style="width: 98%;" v-if="items" class="form-control">
                                    <option v-for="(objItem) in items" v-bind:key="objItem.id" :value="objItem">{{ objItem.itemNm }}</option>
                                </select>
                            </td>
                            <td>
                                <button class="btn btn-primary btn-sm" style="margin-top: 27px;" @click="confirmPurchase(purchaseItem)">Confirm</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <button class="btn-style col-md-5" style="float:right; margin: 20px;" @click="updatePurchase('NORMAL')">Update and Affect Stock Purchase</button>
                    <button class="btn-style col-md-5" style="float:right; margin: 20px;" @click="updatePurchaseService">Update Purchase Status Only</button>
                </div>
            </div>
        </div>
      </section>
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
import { toRaw } from 'vue'

export default {
name: 'PurchasePage',
components: {
    Header,
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        purchases: [],
        codes:[],
        items:[],
        isVisible:false,
        status: "",
        remarks: "",
        item:{},
        currentPurchase:{},
        itemList:[],
        updatedPurchases:[],

    }
},
methods:{
    openModal(obj){
        this.isVisible = true;
        this.currentPurchase = toRaw(obj);
    },
    closeModal() {
        this.isVisible = false;
    },
    formatToMoney(value) {
        const formattedValue = Number(value).toFixed(2);
        return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    confirmPurchase(obj){
        const pObj = toRaw(obj);
        const itemSeq = this.itemList.length + 1
        var newItem = {
            "itemSeq": itemSeq,
            "itemId":this.item.id,
            "itemCd": this.item.itemCd,
            "itemClsCd": this.item.itemClsCd,
            "itemNm": this.item.itemNm,
            "itemNmDef": this.item.itemCdDf,
            "bcd": pObj.bcd,
            "spplrItemClsCd": pObj.itemClsCd,
            "spplrItemCd": pObj.itemCd,
            "spplrItemNm": pObj.itemNm,
            "pkgUnitCd": pObj.pkgUnitCd,
            "pkg": pObj.pkg,
            "qtyUnitCd": pObj.qtyUnitCd,
            "qty": pObj.qty,
            "prc": pObj.prc,
            "splyAmt": pObj.splyAmt,
            "dcRt": pObj.dcRt,
            "dcAmt": pObj.dcAmt,
            "taxblAmt": pObj.taxblAmt,
            "taxTyCd": pObj.taxTyCd,
            "taxAmt": pObj.taxAmt,
            "totAmt": pObj.totAmt,
            "itemExprDt": null
        }
        this.itemList.push(newItem);
        this.item = {}
    },
    async updatePurchaseService(){
        this.itemList = [];
        this.currentPurchase.itemList.forEach(item => { 
            var newItem = {
                "itemSeq": item.itemSeq,
                "itemId":1,
                "itemCd": item.itemCd,
                "itemClsCd": item.itemClsCd,
                "itemNm": item.itemNm,
                "itemNmDef": item.itemNm,
                "bcd": item.bcd,
                "spplrItemClsCd": item.itemClsCd,
                "spplrItemCd": item.itemCd,
                "spplrItemNm": item.itemNm,
                "pkgUnitCd": item.pkgUnitCd,
                "pkg": item.pkg,
                "qtyUnitCd": item.qtyUnitCd,
                "qty": item.qty,
                "prc": item.prc,
                "splyAmt": item.splyAmt,
                "dcRt": item.dcRt,
                "dcAmt": item.dcAmt,
                "taxblAmt": item.taxblAmt,
                "taxTyCd": item.taxTyCd,
                "taxAmt": item.taxAmt,
                "totAmt": item.totAmt,
                "itemExprDt": null
            }
            this.itemList.push(newItem);            
        });
        await this.updatePurchase("PURCHASE");
    },
    async updatePurchase (type) {
        const dateTimeNow = this.getCurrentDateTimeFormatted()
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                "invcNo": 1,
                "orgInvcNo": 0,
                "spplrTin": this.currentPurchase.spplrTin,
                "spplrBhfId": this.currentPurchase.spplrBhfId,
                "spplrNm": this.currentPurchase.spplrNm,
                "spplrInvcNo": this.currentPurchase.spplrInvcNo,
                "regTyCd": "M",
                "pchsTyCd": "N",
                "rcptTyCd": "P",
                "type": type,
                "pmtTyCd": this.currentPurchase.pmtTyCd,
                "pchsSttsCd": this.status,
                "cfmDt": dateTimeNow,
                "pchsDt": this.currentPurchase.salesDt,
                "wrhsDt": "",
                "cnclReqDt": "",
                "cnclDt": "",
                "rfdDt": "",
                "totItemCnt": this.itemList.length,
                "taxblAmtA": this.currentPurchase.taxblAmtA,
                "taxblAmtB": this.currentPurchase.taxblAmtB,
                "taxblAmtC": this.currentPurchase.taxblAmtC,
                "taxblAmtD": this.currentPurchase.taxblAmtD,
                "taxblAmtE": this.currentPurchase.taxblAmtE,
                "taxRtA": this.currentPurchase.taxRtA,
                "taxRtB": this.currentPurchase.taxRtB,
                "taxRtC": this.currentPurchase.taxRtC,
                "taxRtD": this.currentPurchase.taxRtD,
                "taxRtE": this.currentPurchase.taxRtE,
                "taxAmtA": this.currentPurchase.taxAmtA,
                "taxAmtB": this.currentPurchase.taxAmtB,
                "taxAmtC": this.currentPurchase.taxAmtC,
                "taxAmtD": this.currentPurchase.taxAmtD,
                "taxAmtE": this.currentPurchase.taxAmtE,
                "totTaxblAmt": this.currentPurchase.totTaxblAmt,
                "totTaxAmt": this.currentPurchase.totTaxAmt,
                "totAmt": this.currentPurchase.totAmt,
                "remark": this.remarks,
                "regrNm": "Admin",
                "regrId": "Admin",
                "modrNm": "Admin",
                "modrId": "Admin",
                "itemList": this.itemList
            })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'purchases updated successfully',
                text: data.message
            })
            console.log(data)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'purchases not found',
                text: data.message
            })
        }
    },
    async getPurchases () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/purchases', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.purchases = data.purchases
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Purchase not found',
                text: data.message
            })
        }
    },
    async getUpdatePurchases () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/purchases/updated', requestOptions)
        const data = await res.json()
        console.log(data)
        this.updatedPurchases = data.purchases
    },
    isStringInArray(task) {
        return this.updatedPurchases.some(obj => obj.spplrInvcNo==task );
    },
    async getCodes () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items/pullcodes', requestOptions)
        const data = await res.json()
        this.codes = data.kraResult.clsList;
    },
    async getItems () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.items = data.items
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not found',
                text: data.message
            })
        }
    },
    roundToTwo(num) {
      return parseFloat(num.toFixed(2));
    },
    getCurrentDateFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');

      return `${year}${month}${day}`;
    },
    getCurrentDateTimeFormatted() {
      const now = new Date();

      const year = now.getFullYear();
      // Get month, add 1 as getMonth() returns 0-11, and pad with zero if necessary
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }
},
mounted(){
    this.getPurchases()
    this.getCodes()
    this.getItems()
    this.getUpdatePurchases()
}
}
</script>


<style scoped>
.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>