<template>
    <div class="login">
  
      <Header :loginHeader="true" />
  
      <section id="login">
        <div class="container text-center">
          <div class="login-wrapper">
            <h3>iSale Web </h3>
            <p>Initialize The Account</p>
            <div class="form">
              <div class="form-group">
                <input type="text" placeholder="Business PIN" v-model="pin" class="form-control">
              </div>
              <div class="form-group">
                <input type="text" placeholder="Branch Id" v-model="branch" class="form-control">
              </div>
              <div class="form-group">
                <input type="text" placeholder="Device Serial Number" v-model="device" class="form-control">
              </div>
              <div class="form-group">
                <input type="button" value="Initialize" @click="submitInitialize" class="btn-style full">
              </div>
            </div>
          </div>
        </div>
      </section>
  
  
    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  import Swal from 'sweetalert2'
  
  export default {
    name: 'HomePage',
    components: {
      Header,
    },
    data: () => ({
      pin: "",
      branch: "",
      device: "",
      rules: [
          value => !!value || 'Required.',
          value => (value && value.length >= 4) || 'Min 4 characters',
      ]
    }),
    methods:{
      async submitInitialize () {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                pin: this.pin,
                branch: this.branch,
                device: this.device
            })
        };
        const res = await fetch(process.env.VUE_APP_URL +'/auth/initialize', requestOptions)
        const data = await res.json()
        if(data.status == true){
          console.log({data})
            this.$router.push("/items")
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Initialization failed',
                text: data.message
            })
        }
      }
    },
  }
  </script>