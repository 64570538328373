<template>
    <div class="transaction">
      
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3 class="item-header">Item Management
            <button class="pull-right btn btn-primary" style="float:right;margin:10px;" @click="openModal">Add Item</button>
            <button class="pull-right btn btn-default" style="float:right;margin:10px; border: 1px solid #000;" @click="uploadItems">Upload Items</button>
            <!-- <a-button class="pull-right" @click="clearFilters" style="float: right; margin: 10px">Clear filters</a-button> -->
            <!-- <button class="pull-right btn btn-primary" style="float:right;margin:10px;" @click="getItemCodes">Pull Classification Codes</button> -->
            
            <div class="col-md-6 align-self-center" :style="{ zIndex: 200, display: displayUpload, backgroundColor: 'rgb(190, 190, 190)', padding: '20px', position: 'absolute', 'marginLeft': '200px', 'borderRadius': '5px'}">
                <div class="upload-products">
                <a target="_blank" href="https://docs.google.com/spreadsheets/d/e/2PACX-1vThr7jyJMMiPSXuoXal0Iunl1NP2lGrl03krRjc-ObEuqg9uYrCgpHVvPG3eVSvYyseLj6wVv5fLw8d/pub?gid=0&single=true&output=csv" class="btn btn-warning btn-sm">Download Upload Template</a>
                <button class="btn btn-danger btn-sm" style="float: right;" @click="closeUploadItems">Close</button>
                <div class="form">
                    <label for="upload-products">Choose product file (CSV) to upload <i class="fa fa-upload"></i></label>
                    <input type="file" class="form-control"  id="upload-products" placeholder="Upload Products" accept=".csv"  ref="fileUpload" @change="handleFileChange">
                </div>
                </div>
            </div>
            
            <div class="col-md-6 align-self-center" :style="{ display: displayStock, backgroundColor: '#fff', border: '1px solid #000', padding: '20px', position: 'absolute', 'marginLeft': '200px', 'borderRadius': '5px', zIndex: 120}">
                <div class="upload-products">
                    <h4>Adjust Item stock <button class="btn btn-danger btn-sm" style="float: right;" @click="closeAdjust">Close</button></h4>
                    <hr>
                <div class="form">
                    <div class="form-group">
                        <label for="">Select Adjustment Reason</label>
                        <select v-model="adjustmentId" style="width: 100%;">
                            <option value="2">Incoming Adjustment</option>
                            <option value="4">Outgoing  Adjustment</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Quantity to Adjust </label>
                        <input type="number" min="0" v-model="adjustQty" class="form-control"/>
                    </div>
                    <div class="form-group">
                        <button class="btn-style full" @click="postAdjust">Adjust Stock</button>
                    </div>
                    
                    
                </div>
                </div>
            </div>

        </h3>

        <div style="width:100%;margin-top:20px;padding:10px" :style="{ 'display': showAddModal }">
            <h4>Add Item</h4>
            <div class="row">
                <div class="col-md-6">
                    <label for="">Item Classification</label>
                    <select v-model="classification" style="width: 98%; border: 1px solid #3a3a3a;" v-if="classifications.length > 0">
                        <option v-for="(classific) in classifications" v-bind:key="classific.id" :value="classific.itemClsCd">{{ classific.itemClsNm }}</option>
                    </select>
                    <p for="" v-else>Wait. Loading ...</p>
                    <label for="">Product Type</label>
                    <select v-model="productType" style="width: 98%; border: 1px solid #3a3a3a;" v-if="codes[9]">
                        <option v-for="(objItem) in codes[9].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                    </select>
                    <p for="" v-else>Wait. Loading ...</p>
                    <label for="">Country of Origin</label>
                    <select v-model="country" style="width: 98%; border: 1px solid #3a3a3a;" v-if="codes[1]">
                        <option v-for="(objItem) in codes[1].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                    </select>
                    <p for="" v-else>Wait. Loading ...</p>
                    <label for="">Packaging Unit</label>
                    <select v-model="packagingUnit" style="width: 98%; border: 1px solid #3a3a3a;" v-if="codes[8]">
                        <option v-for="(objItem) in codes[8].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                    </select>
                    <p for="" v-else>Wait. Loading ...</p>
                    <label for="">Quantity Unit</label>
                    <select v-model="quantityUnit" style="width: 98%; border: 1px solid #3a3a3a;" v-if="codes[4]">
                        <option v-for="(objItem) in codes[4].dtlList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                    </select>
                    <p for="" v-else>Wait. Loading ...</p>
                    <label for="">Tax type</label>
                    <select v-model="taxType" style="width: 98%; border: 1px solid #3a3a3a;" v-if="codes[0]">
                        <option v-for="(objItem) in filteredCodeList" v-bind:key="objItem.id" :value="objItem.cd">{{ objItem.cdNm }}</option>
                    </select>
                    <p for="" v-else>Wait. Loading ...</p>

                    <button class="btn btn-primary" style="margin-top:20px;" @click="postItem">Add Item</button>
                    <button class="btn btn-danger" style="margin-top:20px;margin-left: 20px;" @click="closeModal">Close</button>
                </div>
                <div class="col-md-6">
                    <label for="">Item Name</label>
                    <input type="text" v-model="name" class="form-control" style="background:#fff; border: 1px solid #000;">
                    <label for="">Barcode</label>
                    <input type="text" v-model="barcode" class="form-control" style="background:#fff; border: 1px solid #000;">
                    <label for="">Batch No.</label>
                    <input type="text" v-model="batch" class="form-control" style="background:#fff; border: 1px solid #000;">
                    <label for="">Price</label>
                    <input type="number" min="0" v-model="price" class="form-control" style="background:#fff; border: 1px solid #000;">
                    <label for="">Declare Initial Stock</label>
                    <input type="number" min="0" v-model="stock" class="form-control" style="background:#fff; border: 1px solid #000;">
                </div>
            </div>
        </div>
          <!-- <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Price</th>
                    <th>Tax Code</th>
                    <th>Current Stock</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="items.length > 0">
                <tr v-for="(item,index) in items" v-bind:key="item.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ item.itemCd }}</td>
                    <td>{{ item.itemNm }}</td>
                    <td>KES {{ formatToMoney(item.dftPrc) }}</td>
                    <td>{{ item.taxTyCd }}</td>
                    <td>{{ formatToMoney(item.currentStock) }}</td>
                    <td>
                      <div class="btn-group" role="group">
                        <button type="button" class="btn btn-default btn-sm" style="border: 1px solid #000;" data-bs-toggle="dropdown" aria-expanded="true">Action</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" @click="viewAdjust(item.id)">Adjust Stock</a></li>
                        </ul>
                      </div>
                    </td>
                </tr>
                
            </tbody>
            <p v-else  style="color: red; padding: 20px; font-size: 21px;">No Items found</p>
        </table> -->

        <a-table 
            :columns="columns" 
            :loading="loading" 
            class="ant-table-striped" 
            :dataSource="state.list" 
            :pagination="pagination"
            style="font-family: Roboto;" 
            @change="handleTableChange"
            bordered
            
            size="small"
        >
            <template #headerCell="{ column }">
                <template v-if="column.key === 'custNm'">
                    <span style="color: #1890ff">Name</span>
                </template>
            </template>
            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                    <a-input
                    ref="searchInput"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />
                        
                    <a-button
                        type="primary"
                        size="small"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >
                        <template #icon><SearchOutlined /></template>
                        Search
                    </a-button>
                    <a-button class="resetBtn" size="small" style="width: 90px" @click="handleReset(clearFilters)">
                        Reset
                    </a-button>
                </div>
            </template>
            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <template #bodyCell="{ column, record, index, text }">
                <span v-if="state.searchText && state.searchedColumn === column.dataIndex">
                    <template
                    v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${state.searchText})|(?=${state.searchText})`, 'i'))"
                    >
                    <mark
                        v-if="fragment.toLowerCase() === state.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                    >
                        {{ fragment }}
                    </mark>
                    <template v-else>{{ fragment }}</template>
                    </template>
                </span>
                <template v-if="column.dataIndex == 'action'">
                    <div class="btn-group" role="group">
                        <button type="button" class="btn btn-primary btn-sm actionBtn" style="border: 1px solid #000;" data-bs-toggle="dropdown" aria-expanded="true">Action</button>
                        
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" @click="viewAdjust(record.id)">Adjust Stock</a></li>
                            <!-- <li><a class="dropdown-item" @click="viewDelete(record.id)">Delete Stock</a></li> -->
                        </ul>
                      </div>
                </template>
                <template v-else-if="column.dataIndex == 'no'">
                    <span>{{ index + 1 }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'dftPrc'">
                    <span>KES {{ formatToMoney(text) }}</span>
                </template>
                <!-- <template v-else-if="column.dataIndex == ''">
                    <span>KES {{ formatToMoney(text) }}</span>
                </template> -->
            </template>
        </a-table>

        <!-- Confirm Delete Modal -->
        <div v-if="showDeleteModal" class="modal">
        <div class="modal-content">
            <span class="close" @click="closeDeleteModal">&times;</span>
            <p class="modal-text">Are you sure you want to delete the stock for {{ currentItem.itemNm }}?</p>
            <div class="modal-buttons">
            <button class="btn btn-danger" @click="deleteItem">Delete</button>
            <button class="btn btn-secondary" @click="closeDeleteModal">Cancel</button>
        </div>
        </div>
        </div>
        </div>
      </section>
  
      <div v-show="isVisible" class="modal">
            <div class="modal-content">
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModalComposition">&times;</span>
                <div class="row">
                    <h4>Selected item to setup composition: {{ currentItem.itemName }}</h4>
                    <div class="col-md-5">
                        <label for="">Select Item for Composition</label>
                        <select v-model="itemComposition" style="width: 98%;" v-if="items" class="form-control">
                            <option v-for="(objItem) in items" v-bind:key="objItem.id" :value="objItem">{{ objItem.itemNm }}</option>
                        </select>
                    </div>
                    <div class="col-md-7">
                        <label for="">Quantity composed</label>
                        <input type="number" min="1" v-model="quantity" class="form-control" />
                    </div>
                </div>
                <button class="btn-style" style="float:right; margin: 20px;" @click="sendComposition">Send Composition</button>
            </div>
        </div>


        <div v-show="isVisible" class="modal">
            <div class="modal-content">
                <span class="close" style="position:absolute; width: fit-content; right: 0; margin-right: 20px;" @click="closeModalComposition">&times;</span>
                <P>Are you sure You want to Delete the Stock?</P>
                <button class="btn-style" style="float:right; margin: 20px;" @click="sendComposition">Send Composition</button>
            </div>
        </div>
    </div>
  </template>

<style scoped>
select{
    padding: 7px;
}
</style>

<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
import { toRaw, reactive, computed, ref, defineComponent, watch } from 'vue'
import { usePagination } from "vue-request";
import { SearchOutlined } from "@ant-design/icons-vue";
import axios from 'axios';
// import Button from "primevue/button";

// import Dialog from 'primevue/dialog';
// import InputText from 'primevue/inputtext';

// import { ref } from "vue";



const state = reactive({
  searchText: '',
  searchedColumn: '',
  list: [],
  total: 0,
  current: 1,
  pageSize: 0,
  totalPages: 0
});


const searchInput = ref();



console.log("VIEWED DATA")

const queryData = async (params) => {
    
    let { page, results, itemNm } = params
    let offset = (page -  1) * results;
    
    let token = JSON.parse(localStorage.getItem('access_token'))
    
    if(!itemNm) {
        let response = await axios.get(process.env.VUE_APP_URL + `/items/?offset=${offset}&limit=${results}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })
    
        if (response) {
            window.scrollTo({
                    top:0,
                    behavior: "smooth"
            })
            return response.data;
        } 

        
    } else {
        let response = await axios.get(process.env.VUE_APP_URL + `/items/?offset=${offset}&limit=${results}&itemNm=${itemNm}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })
        // let btns = document.getElementsByClassName("resetBtn")
        // btns.forEach(element => element.click())
        if (response) {
            window.scrollTo({
                    top:0,
                    behavior: "smooth"
            })
            return response.data;
        } 
    }
       
}




export default defineComponent({
name: 'ItemPage',
components: {
    SearchOutlined,
    Header,
    // Button,
    // Dialog,
},




setup() {
    
    const { data, pageSize, run, current, loading } = usePagination(queryData, {
        formatResult: res => res.data,
        pagination: {
            currentKey: 'page',
            pageSizeKey: 'results',
            totalKey: state.total,
            totalPageKey: state.totalPages
        },
    });

    
    
    console.log('RESULTS')
    
    const pagination = computed(() => ({
        total: state.total,
        current: current.value,
        pageSize: pageSize.value,
        // totalPageKey: state.totalPages
    }))

    watch(data, (newValue) => {
        state.total = newValue.total
        state.list = newValue.items
        state.pageSize = pageSize
        state.totalPages = newValue.totalPages
        
        console.log("Data:", newValue);
        console.log(pagination)
    });

    const handleTableChange = (pag, filters, sorter) =>{
        console.log("BAMBARRARRRRRRRRRRR")
        console.log(pag)
        console.log(filters)
        console.log(sorter)
        run({
            results: pag.pageSize,
            page: pag?.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    return {
        pageSize,
        loading,
        pagination,
        run,
        handleTableChange,
        state
    }
},

data(){
    return{
        columns: [
                {
                    title: 'No',
                    dataIndex: 'no',
                },
                {
                    title: "Item Code",
                    dataIndex: "itemCd",
                    key: "itemCd",
                },
                {
                    title: "Item Name",
                    dataIndex: "itemNm",
                    key:"itemNm",
                    customFilterDropdown: true,
                    // ellipsis: true,
                    onFilter: (value, record) => record.itemNm.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                        searchInput?.value?.focus();
                        }, 100);
                     }
                    }
                },
                {
                    title: 'Price',
                    dataIndex: 'dftPrc',
                    key: 'dftPrc',
                },
                {
                    title: 'Tax Code',
                    dataIndex: 'taxTyCd',
                    key: 'taxTyCd',
                },
                {
                    title: 'Current Stock',
                    dataIndex: 'currentStock',
                    key: 'currentStock',
                },
                {
                    title: "Action",
                    dataIndex: "action",
                    // fixed: "right",
                    // width: 200
                }

            ],
        token: JSON.parse(localStorage.getItem('access_token')),
        items: [],
        showAddModal: "none",
        classifications: [],
        codes: [],
        classification:"",
        adjustmentId:"",
        adjustQty: "",
        productType:"",
        country:"",
        packagingUnit:"",
        quantityUnit:"",
        taxType:"",
        name:"",
        barcode:"",
        batch:"",
        price:"",
        stock:"",
        isVisible: false,
        currentItem: {},
        itemComposition: {},
        quantity:"",
        displayUpload: "none",
        displayStock: "none",
        uploadProduct:"",
        adjustItemId:""
    }
},
methods:{

    viewDelete() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.currentItem = {};
    },
    async deleteItem(itemId) {
        try {
            const response = await fetch(`${process.env.VUE_APP_URL}/items/delete`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + this.token,
                },
                body: JSON.stringify({
                itemId: itemId,
                itemCd: "", 
                itemCdDf: "", 
                itemNm: "", 
                itemStdNm: "",
                btchNo: "", 
                bcd: "", 
                dftPrc: "", 
                itemCategoryId: null 
            }),
            });
            console.log(response)

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Update UI or show a success message
            this.closeDeleteModal();
        } catch (e) {
            console.error('Failed to delete stock:', e);
        }
    },

    showModal(){
        open.value = true;
    },

    handleOk(e) {
        console.log(e);
        open.value = false;
    },
    confirm1() {
        
    },
    clearFilters() {
        location.reload()
    },
    async handleSearch(selectedKeys, confirm, dataIndex) {
        console.log("MY SELECTED KEYS")
            console.log(selectedKeys)
            
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex; 
    },
    handleReset(clearFilters) {
        clearFilters({
            confirm: true,
        });
        state.searchText = '';      
    },
    openModal() { this.showAddModal = "block"; },
    closeModal() { this.showAddModal = "none"; },
    openModalComposition(obj){
        this.isVisible = true;
        this.currentItem = toRaw(obj);
    },
    closeModalComposition() {
        this.isVisible = false;
    },
    viewAdjust(id){
        this.displayStock = "block"
        this.adjustItemId = id
    },
    async postItem () {
        if(this.name != "" && this.price != "" && this.taxType != ""){
            const itemcds = this.country+this.productType+this.packagingUnit+this.quantityUnit+this.classification+"1"
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({
                    addInfo: "",
                    itemCd: itemcds+"1",
                    itemCdDf: itemcds,
                    itemClsCd: this.classification,
                    itemTyCd: this.productType,
                    modrId: "Admin",
                    modrNm: "Admin",
                    itemNm: this.name,
                    itemStdNm: this.name,
                    orgnNatCd: this.country,
                    pkgUnitCd: this.packagingUnit,
                    qtyUnitCd: this.quantityUnit,
                    taxTyCd: this.taxType,
                    btchNo: this.batch,
                    bcd: this.barcode,
                    currentStock: this.stock,
                    dftPrc: this.price,
                    grpPrcL1: 10.0,
                    grpPrcL2: 10.0,
                    grpPrcL3: 10.0,
                    grpPrcL4: 10.0,
                    isrcAplcbYn: "N",
                    useYn: "Y"

                })
            };
            this.name = ""
            this.price = ""
            this.taxType = ""
            const res = await fetch(process.env.VUE_APP_URL+'/items', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Item added successfully',
                    text: data.message
                })
                this.name = ""
                this.barcode = ""
                this.batch = ""
                this.price = ""
                this.stock = ""
                // this.classifications = []
                // this.codes = []
                this.closeModal()
                this.getItems()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Items not found',
                    text: data.message
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Fields or Data',
                text: "Fill in all the fields "
            })
        }
    },
    async postAdjust () {
        if(this.adjustQty != "" && this.adjustmentId != ""){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    itemId: this.adjustItemId,
                    reasonId: this.adjustmentId,
                    qty: this.adjustQty
                })
            };
            this.adjustQty = ""
            this.adjustmentId = ""
            const res = await fetch(process.env.VUE_APP_URL+'/stock/adjust', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Item stock adjusted successfully',
                    text: data.message
                })
                this.displayStock = "none"
                this.getItems();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Item not found',
                    text: data.message
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Fields or Data',
                text: "Fill in all the fields "
            })
        }
    },
    async getItems () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.items = data.items
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Items not found',
                text: data.message
            })
        }
    },
    uploadItems(){
        this.displayUpload = "block"
    },
    closeUploadItems(){
        this.displayUpload = "none"
    },
    closeAdjust(){
        this.displayStock = "none"
    },
    async getCodes () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items/pullcodes', requestOptions)
        const data = await res.json()
        console.log({data});
        this.codes = data.kraResult.clsList;
        // console.log(data)
        // if(data.status == true){
        //     this.codes = data.kraResult
        // }
    },
    async getClassifications () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/items/pullclassifications', requestOptions)
        const data = await res.json()
        console.log({data});
        this.classifications = data.kraResult.itemClsList
        // console.log(data)
        // if(data.status == true){
        //     this.classifications = data.kraResult
        // }
    },
    async getItemCodes(){
        this.getCodes()
        this.getClassifications()
    },
    formatToMoney(value) {
        const formattedValue = Number(value).toFixed(2);
        return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    async uploadProducts() {
        try {
            let formData = new FormData();
            formData.append("file", this.uploadProduct);

            const response = await fetch(process.env.VUE_APP_URL + '/items/upload', {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": 'Bearer ' + this.token
                }
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            if (data.status === true) {
                await Swal.fire({
                    icon: 'success',
                    title: 'Products uploaded successfully',
                    text: data.message,
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm'
                });

                window.location.reload();
            } else {
                await Swal.fire({
                    icon: 'error',
                    title: 'Product upload failed',
                    text: data.message
                });
            }
        } catch (error) {
            console.error('Error during product upload:', error);
            await Swal.fire({
                icon: 'error',
                title: 'Product upload failed',
                text: 'An error occurred during the upload process. Please try again later.'
            });
        }
    }
    ,
      handleFileChange() {
        this.uploadProduct = this.$refs.fileUpload.files[0];
      }
},
mounted(){
    this.getItems()
    this.getItemCodes()
},
computed: {
    filteredCodeList() {
        return this.codes[0].dtlList.filter(objItem => objItem.cd != "E");
    },
},
watch:{
    uploadProduct(){
      this.uploadProducts()
    }
}
})
</script>


<style scoped>

.ant-table-wrapper {
    /* display: flex;
    justify-content: center;
    align-items: center;  */
    margin:0 auto;
    width: 90vw;
}
.ant-table, .ant-table-content {
    width: 90vw;
    margin:0 auto;    
}

.table {
    font-family: Roboto, sans-serif;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
}

.item-header {
    width: 90vw;
    font-family: Roboto, sans-serif;
    margin-left: auto;
    margin-right: auto;
}
.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}



.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.actionBtn {
  padding: 0 5px;
  line-height: 10px;
  height: 35px;
  vertical-align: middle;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 500px;
    width: 100%;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-danger {
    background-color: #dc3545;
    color: #fff;
}
.modal-text {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
}
.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}
</style>