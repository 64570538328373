<template>
  <header id="header">
    <div class="containers" style="font-family: Roboto, sans-serif !important">
      <div class="row" v-if="loginHeader === true">
        <div class="col-md-6 align-self-center">
          <a href="#" class="logo">
            <img src="../assets/images/logo_black.png" alt="" style="width: 90px;">
          </a>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="select-languages">
           <div class="form">
             <select>
               <option value="English">Eng</option>
             </select>
           </div>
            <div class="img-wrapper">
              <i class="fa-solid fa-circle-question"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row main-header" v-if="mainHeader === true">
        <div class="col-md-12 align-self-end">
          <RouterLink to="home" class="logo">
            <img src="../assets/images/logo_black.png" alt="" style="height: 50px !important;">
          </RouterLink>
          <ul class="menu" style="font-family: Roboto, sans-serif !important">
            <li><RouterLink to="/items" exact-active-class="active-tab">Items</RouterLink></li>
            <li><RouterLink to="/sales" exact-active-class="active-tab">Sales</RouterLink></li>
            <li><RouterLink to="/credit" exact-active-class="active-tab">Credit Note</RouterLink></li>
            <li><RouterLink to="/customer" exact-active-class="active-tab">Customers</RouterLink></li>
            <li><RouterLink to="/purchases" exact-active-class="active-tab">Purchases</RouterLink></li>
            <li><RouterLink to="/imports" exact-active-class="active-tab">Imports</RouterLink></li>
            <!-- <li><RouterLink to="/profoma">Profoma</RouterLink></li> -->
            <!-- <li><RouterLink to="/movement">Stock Movement</RouterLink></li> -->
            <li><RouterLink to="/insurance" exact-active-class="active-tab">Insurances</RouterLink></li>
            <li><RouterLink to="/user" exact-active-class="active-tab">Users</RouterLink></li>
            <li><RouterLink to="/notice" exact-active-class="active-tab">Notices & Branch</RouterLink></li>
            <li><a :href="'//' + 'reports.stanbestgroup.com'" target="_blank">Reports</a></li>

            <li class="logout-item">
              <button class="logout-button" @click="logout">Logout</button>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: ['loginHeader', 'mainHeader'],
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      this.$router.replace("/")
    },
  }
}
</script>


<style scoped>
.menu {
  font-family: Roboto, sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu li {
  margin-right: 20px;
}

.logout-item {
  margin-left: auto; 
}

.logout-button {
  padding: 8px 16px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.active-tab {
  color: #ff4d4d;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.active-tab:hover {
  transform: scale(1.1);
}

.logout-button:hover {
  background-color: #ff1a1a;
  transform: scale(1.1);
}
.active-tab {
  text-decoration: none;
}
.menu li a:not(.active-tab) {
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.menu li a:not(.active-tab):hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

</style>