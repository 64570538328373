<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>Insurance Management<button class="pull-right btn btn-primary" style="float:right;margin:10px;" @click="addInsurance= 'block'">Add Insurance</button></h3>
          <div :style="{ 'display': addInsurance }">
                <div style="width: 50%; padding:10px;">
                    <h5>Add Insurance</h5>
                    <label for="">Insurance Code</label>
                    <input type="text" name="code" v-model="code" class="form-control" style="background:#fff; border: 1px solid #000">

                    <label for="">Insurance Name</label>
                    <input type="text" name="name" v-model="name" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">Premium Rate</label>
                    <input type="number" min="0" max="100" name="rate" v-model="rate" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <button class="btn-style full" @click="postInsurance" style="margin-top: 10px;">Add Insurance</button>
                </div>
            </div>
          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Insurance Code</th>
                    <th>Insurance Name</th>
                    <th>Premium Rate</th>
                </tr>
            </thead>
            <tbody v-if="insurances">
                <tr v-for="(insurance,index) in insurances" v-bind:key="insurance.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ insurance.isrccCd }}</td>
                    <td>{{ insurance.isrccNm }}</td>
                    <td>{{ insurance.isrcRt }}</td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else>No Insurances found</p>
        </table>
        </div>
      </section>
  
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'

export default {
name: 'InsurancePage',
components: {
    Header,
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        insurances: [],
        name: "",
        code: "",
        rate: "",
        addInsurance: "none"
    }
},
methods:{
    async getInsurances () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/insurance', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.insurances = data.insurances
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Insurances not found',
                text: data.message
            })
        }
    },
    
    async postInsurance() {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
                isrccCd: this.code,
                isrccNm: this.name,
                isrcRt: this.rate,
                useYn: "Y"
          })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/insurance', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Insurance created Successful',
                text: data.message
            })
            this.getInsurances()
            this.addInsurance = "none"
            this.rate = ""
            this.code = ""
            this.name = ""
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Insurance not found',
                text: data.message
            })
        }

    }
},
mounted(){
    this.getInsurances()
}
}
</script>