<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
          <h3>User Management<button class="pull-right btn btn-primary" style="float:right;margin:10px;" @click="openModal">Add User</button></h3>

            <div :style="{ 'display': showAddModal }">
                <div style="width: 50%; padding:10px;">
                    <h5>Add new User</h5>
                    <label for="">Username</label>
                    <input type="text" name="pin" v-model="username" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">User Name</label>
                    <input type="text" name="name" v-model="name" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">User Email</label>
                    <input type="email" name="email" v-model="email" class="form-control" style="background:#fff; border: 1px solid #000">
                    
                    <label for="">User Phone</label>
                    <input type="text" name="phone" v-model="phone" class="form-control" style="background:#fff; border: 1px solid #000">
                    <button class="btn-style full" @click="postUser" style="margin-top: 10px;">Add User</button>
                </div>
            </div>

          <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>username</th>
                </tr>
            </thead>
            <tbody v-if="users.length > 0">
                <tr v-for="(user,index) in users" v-bind:key="user.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.phone }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.username }}</td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
            <p v-else  style="color: red; padding: 20px; font-size: 21px;">No Users found</p>
        </table>

        </div>
      </section>
  
    </div>
  </template>
  
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'

export default {
name: 'UserPage',
components: {
    Header
},
data(){
    return{
        token: JSON.parse(localStorage.getItem('access_token')),
        users: [],
        showAddModal: "none",
        name: "",
        username: "",
        phone: "",
        email: ""
    }
},
methods:{
    openModal() { this.showAddModal = "block"; },
    closeModal() { this.showAddModal = "none"; },
    async getUsers () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/users', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.users = data.users
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Users not found',
                text: data.message
            })
        }
    },

    async postUser() {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            body: JSON.stringify({ 
              username: this.username,
              userNm: this.name,
              adrs: this.email,
              cntc: this.phone
          })
        };
        const res = await fetch(process.env.VUE_APP_URL+'/users', requestOptions)
        const data = await res.json()
        if(data.status == true){
            Swal.fire({
                icon: 'success',
                title: 'Users Created Successful',
                text: data.message
            })
            this.getUsers()
            this.showAddModal = "none"
            this.email = ""
            this.username = ""
            this.phone = ""
            this.name = ""
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Users not found',
                text: data.message
            })
        }

    }
},
mounted(){
    this.getUsers()
}
}
</script>