<template>
    <div class="transaction">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products" style="padding: 10px 0px !important">
        <div class="containers">
        <div style="display:flex; justify-content: space-between; align-items: center; width: 90vw; margin: 0 auto">
          <h3 class="credit-header">Credit Note Invoices</h3>
          <a-button class="pull-right" @click="clearFilters" style="float: right; margin: 10px">Clear filters</a-button>
        </div>
          <a-table 
            :columns="columns" 
            :loading="loading" 
            class="ant-table-striped" 
            :dataSource="state.list" 
            :pagination="pagination"
            style="font-family: Roboto;" 
            @change="handleTableChange"
            bordered
        >
            <template #headerCell="{ column }">
                <template v-if="column.key === 'custNm'">
                    <span style="color: #1890ff">Name</span>
                </template>
            </template>
            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                    <a-input
                    ref="searchInput"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />
                        
                    <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >
                        <template #icon><SearchOutlined /></template>
                        Search
                    </a-button>
                    <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                        Reset
                    </a-button>
                </div>
            </template>
            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <template #bodyCell="{ column, record, index, text }">
                <span v-if="state.searchText && state.searchedColumn === column.dataIndex">
                    <template
                    v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${state.searchText})|(?=${state.searchText})`, 'i'))"
                    >
                    <mark
                        v-if="fragment.toLowerCase() === state.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                    >
                        {{ fragment }}
                    </mark>
                    <template v-else>{{ fragment }}</template>
                    </template>
                </span>
                <template v-if="column.dataIndex == 'action'">
                    <button class="btn btn-secondary btn-sm" style="margin-right: 10px" @click="generateCreditQR(record)">Generate QR</button>
                </template>
                <template v-else-if="column.dataIndex == 'no'">
                    <span>{{ index + 1 }}</span>
                </template>
                <template v-else-if="column.dataIndex == 'totAmt'">
                    <span>KES {{ formatToMoney(text) }}</span>
                </template>
                <template v-else-if="column.dataIndex === 'long_url'">
                    <a v-bind:href=text target="_blank">
                        <span class="pi pi-external-link"></span>
                    </a>
                </template>
                <template v-else-if="column.dataIndex == 'totTaxAmt'">
                    <span>KES {{ formatToMoney(text) }}</span>
                </template>
            </template>
        </a-table>
        </div>
      </section>
  
    </div>
  </template>
<style scoped>
    .table {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
    .card {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;

    }

    .credit-header {
        font-family: Roboto, sans-serif;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
</style> 
<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2'
// import { ref } from "vue";
// import DataTable from 'primevue/datatable';
// import Column from 'primevue/column';
// import Row from 'primevue/row';
import "@steveyuowo/vue-hot-toast/vue-hot-toast.css";
import "primevue/resources/themes/md-light-indigo/theme.css"
import {reactive, computed, defineComponent, watch, ref } from 'vue'
// import DataTable from 'primevue/datatable';
// import Column from 'primevue/column';

import { usePagination } from "vue-request";
import { SearchOutlined } from "@ant-design/icons-vue";
import axios from 'axios';


const state = reactive({
  searchText: '',
  searchedColumn: '',
  list: [],
  total: 0,
  current: 1,
  pageSize: 0,
  totalPages: 0
});

const searchInput = ref();

const queryData = async (params) => {
    
    let { page, results, custTin, custNm, orgInvcNo } = params
    let offset = (page -  1) * results;
    
    let token = JSON.parse(localStorage.getItem('access_token'))
    
    if(!custTin && !custNm && !orgInvcNo) {
        let response = await axios.get(process.env.VUE_APP_URL + `/credit/?offset=${offset}&limit=${results}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })

        if (response) {
            window.scrollTo({
                top:0,
                behavior: "smooth"
            })
            return response.data;
            
        } 
    } else {
        let response = await axios.get(process.env.VUE_APP_URL + `/credit/?offset=${offset}&limit=${results}&custTin=${custTin}&custNm=${custNm}&origInvcNo=${orgInvcNo}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        })

        if (response) {
            window.scrollTo({
                top:0,
                behavior: "smooth"
            })
            return response.data;
            
        }
    }
       
}


export default defineComponent({
name: 'CreditPage',
components: {
    SearchOutlined,
    Header,
    // DataTable,
    // Column,
   
},

setup() {
        const { data, pageSize, run, current, loading } = usePagination(queryData, {
            formatResult: res => res.data,
            pagination: {
                currentKey: 'page',
                pageSizeKey: 'results',
                totalKey: state.total,
                totalPageKey: state.totalPages
            },
        });

        console.log('RESULTS')
        
        const pagination = computed(() => ({
            total: state.total,
            current: current.value,
            pageSize: pageSize.value,
            // totalPageKey: state.totalPages
        }))

       

        watch(data, (newValue) => {
            state.total = newValue.total
            state.list = newValue.credit
            state.pageSize = pageSize
            state.totalPages = newValue.totalPages
            
            console.log("Data:", newValue);
            console.log(pagination)
        });

    

        const handleTableChange = (pag, filters, sorter) =>{
            console.log("BAMBARRARRRRRRRRRRR")
            console.log(pag)
            console.log(filters)
            console.log(sorter)
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            });
        };

        

        return {
            pageSize,
            loading,
            pagination,
            run,
            handleTableChange,
            state
        }
    },

data(){
    return{
        columns: [
            {
                title: 'No',
                dataIndex: 'no',
            },
            {
                title: "Name",
                dataIndex: "custNm",
                key: "custNm",
                customFilterDropdown: true,
                onFilter: (value, record) => record.custNm?.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => {
                    searchInput?.value?.focus();
                    }, 100);
                 }
                }
            },
            {
                title: "Pin",
                dataIndex: "custTin",
                key:"custTin",
                customFilterDropdown: true,
                onFilter: (value, record) => record.custTin?.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => {
                    searchInput?.value?.focus();
                    }, 100);
                 }
                }
            },
            {
                title: 'Invoice No',
                dataIndex: 'invcNo',
                key: 'invcNo',
            },
            {
                title: 'Original Invoice No',
                dataIndex: 'orgInvcNo',
                key: 'orgInvcNo',
                customFilterDropdown: true,
                onFilter: (value, record) => record.orgInvcNo?.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => {
                    searchInput?.value?.focus();
                    }, 100);
                 }
                }
            },
            {
                title: 'sales Date',
                dataIndex: 'salesDt',
                key: 'salesDt',
            },
            {
                title: 'Items',
                dataIndex: 'totItemCnt',
                key: 'totItemCnt',
            },
            {
                title: 'Link',
                dataIndex: 'long_url',
                key: 'long_url',
            },
            {
                title: 'Amount',
                dataIndex: 'totAmt',
                key: 'totAmt',
                onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => {
                    searchInput.value.focus();
                    }, 100);
                 }
                }
            },
            {
                title: 'Tax',
                dataIndex: 'totTaxAmt',
                key: 'totTaxAmt',
            },
            {
                title: "Action",
                dataIndex: "action"
            }

        ],
        token: JSON.parse(localStorage.getItem('access_token')),
        sales: []
    }
},
methods:{
    clearFilters() {
        location.reload()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
            console.log("MY SELECTED KEYS")
            console.log(selectedKeys)
            
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
    },

    async generateCreditQR(data) {
            try {
                let response = await fetch(process.env.VUE_APP_URL + "/sales/v1/qr",{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${this.token}`,
                        "responseType": "blob"
                    },
                    body: JSON.stringify({
                        type: "credit",
                        id: parseInt(data.id)
                    })
                })

                
                
                if (response.ok) {
                    const blob = await response.blob();
                    const filename = `credit-${data.invcNo}.pdf`;

                    // Create a temporary link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    // Append the link to the body
                    document.body.appendChild(link);

                    // Trigger the download
                    link.click();

                    // Clean up
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                }                
                
        } catch(error) {
            Swal.fire({
                title: "QR Code",
                text: error.message
            })
        } 

        },

    handleReset(clearFilters) {
            
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
            
    },
    formatToMoney(value) {
        const formattedValue = Number(value).toFixed(2);
        return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    async getSales () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_URL+'/credit', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.sales = data.credit
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Credit not found',
                text: data.message
            })
        }
    },
},
mounted(){
    // this.getSales()
}

})
// const products = ref()
</script>
<style scoped>
.ant-table-wrapper {
    /* display: flex;
    justify-content: center;
    align-items: center;  */
    margin:0 auto;
    width: 90vw;
}
.ant-table, .ant-table-content {
    width: 90vw;
    margin:0 auto;    
}
</style>