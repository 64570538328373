<template>
    <div class="signup">
  
        <Header :loginHeader="true" />
        <div>
            <h3>Sign up into iSale </h3>
            <h4>Company/Business Information</h4>
            <label></label>
        </div>
  
    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  import Swal from 'sweetalert2'
  
  export default {
    name: 'SignupPage',
    components: {
      Header,
    },
    data: () => ({
      pin: "",
      username: "",
      password: "",
      deviceId: '',
      deviceDescription: '',
      rules: [
          value => !!value || 'Required.',
          value => (value && value.length >= 4) || 'Min 4 characters',
      ]
    }),
    methods:{
      async signupSubmit () {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                pin: this.pin,
                username: this.username,
                password: this.password,
                platform: "web",
                device_description: this.deviceDescription,
            })
        };
        const res = await fetch(process.env.VUE_APP_URL +'/auth/login', requestOptions)
        const data = await res.json()
        if(data.status == true){
          console.log({data})
            localStorage.setItem('access_token', JSON.stringify(data.access_token))
            this.$router.push("/home")
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Login failed',
                text: data.message
            })
        }
      },
      getDeviceInformation() {
        // Check if the browser supports the necessary properties
        if ('navigator' in window && 'deviceMemory' in navigator && 'hardwareConcurrency' in navigator) {
          // Generate a unique ID based on available device properties
          this.deviceId = this.generateDeviceId();
  
          // Get additional device description
          this.deviceDescription = navigator.userAgent;
        } else {
          console.error('Device information not supported in this browser.');
        }
      },
      generateDeviceId() {
        // You can customize this method based on the properties you want to include in the ID
        const id = `${navigator.userAgent}-${navigator.platform}-${navigator.language}`;
        // Use a hashing library or other techniques for a more secure and unique ID
        return id;
      },
    },
    mounted() {
      this.getDeviceInformation();
    },
  }
  </script>